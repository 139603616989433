const state = {
  isLoading: false,
  items: []
}

const mutations = {
  'TOGGLE_LOADING' (state, payload = false) {
    state.isLoading = !!payload
  },
  'ADD_ITEM' (state, item) {
    state.items.push(item)
  },
  'REMOVE_ITEM' (state, item) {
    const { items } = state
    items.splice(items.indexOf(item), 1)
  },
  'CLEAR_ITEMS' (state) {
    state.items = []
  }
}

const actions = {
  toggleLoading ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING', isLoading)
  },

  addItem ({ commit }, item) {
    if (item.filterBy && item.equalTo && item.fieldOne) {
      commit('ADD_ITEM', item)
    }
  },

  removeItem ({ commit }, item) {
    commit('REMOVE_ITEM', item)
  },

  clearFilterItems ( { commit } ) {
    commit('CLEAR_ITEMS')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
