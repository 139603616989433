var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "alert" } }, [
    _vm.show || _vm.isVisible
      ? _c(
          "div",
          {
            class:
              "tui tuim ui " +
              (_vm.fixed ? "fixed" : "") +
              " message " +
              _vm.type
          },
          [
            _c("div", { staticClass: "message-inner" }, [
              _vm.icon
                ? _c(
                    "span",
                    { staticClass: "tui tuim icon" },
                    [_c("t-icon", { attrs: { icon: ["fas", _vm.icon] } })],
                    1
                  )
                : _vm._e(),
              _vm.close
                ? _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { role: "button" },
                      on: { click: _vm.remove }
                    },
                    [_c("t-icon", { attrs: { icon: ["fas", "times"] } })],
                    1
                  )
                : _vm._e(),
              _c("div", [_vm._t("default")], 2)
            ]),
            _vm.progress && _vm.alertTimer > 0
              ? _c("t-progress-bar", {
                  attrs: { timer: _vm.alertTimer, attached: "" }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }