const state = {
  donorsData: [],
  editForm: false,
  metaDataDonorsList: {},
  donorDetail: {},
  loadingDetail: false,
  filterForm: {},
  filterItems: [],
  totalDonors: 0,
  totalActive: 0,
  totalInactive: 0,
  orderParams: {},
  comercialPhone: null,
  residentialPhone: null,
  mobilePhone: null,
  genderCodes: [
    { key: 'male', text: 'Sr.' },
    { key: 'female', text: 'Sra.' }
  ],
  states: [
    {key: "", name: "Acre", uf: "AC"},
    {key: "", name: "Alagoas", uf: "AL"},
    {key: "", name: "Amapá", uf: "AP"},
    {key: "", name: "Amazonas", uf: "AM"},
    {key: "", name: "Bahia", uf: "BA"},
    {key: "", name: "Ceará", uf: "CE"},
    {key: "", name: "Distrito Federal", uf: "DF"},
    {key: "", name: "Espirito Santo", uf: "ES"},
    {key: "", name: "Goiás", uf: "GO"},
    {key: "", name: "Maranhão", uf: "MA"},
    {key: "", name: "Mato Grosso", uf: "MT"},
    {key: "", name: "Mato Grosso do Sul", uf: "MS"},
    {key: "", name: "Minas Gerais", uf: "MG"},
    {key: "", name: "Pará", uf: "PA"},
    {key: "", name: "Paraíba", uf: "PB"},
    {key: "", name: "Paraná", uf: "PR"},
    {key: "", name: "Pernambuco", uf: "PE"},
    {key: "", name: "Piauí", uf: "PI"},
    {key: "", name: "Rio de Janeiro", uf: "RJ"},
    {key: "", name: "Rio Grande do Norte", uf: "RN"},
    {key: "", name: "Rio Grande do Sul", uf: "RS"},
    {key: "", name: "Rondônia", uf: "RO"},
    {key: "", name: "Roraima", uf: "RR"},
    {key: "", name: "Santa Catarina", uf: "SC"},
    {key: "", name: "São Paulo", uf: "SP"},
    {key: "", name: "Sergipe", uf: "SE"},
    {key: "", name: "Tocantins", uf: "TO"}
  ]
}

const mutations = {
  'SET_DONORS_DATA' (state, payload) {
    state.donorsData = payload
  },
  'SET_METADATA_DONORS_LIST' (state, payload) {
    state.metaDataDonorsList = payload
  },
  'SET_TOTAL_DONORS' (state, payload) {
    state.totalDonors = payload
  },
  'SET_TOTAL_ACTIVE' (state, payload) {
    state.totalActive = payload
  },
  'SET_TOTAL_INACTIVE' (state, payload) {
    state.totalInactive = payload
  },
  'ADD_ITEM' (state, item) {
    state.filterItems.push(item)
  },
  'REMOVE_ITEM' (state, item) {
    const { filterItems } = state
    filterItems.splice(filterItems.indexOf(item), 1)
  },
  'CLEAR_ITEMS' (state) {
    state.filterItems = []
  },
  'CLEAR_FILTER_FORM' (state) {
    state.filterForm = {}
  },
  'SET_DONOR_DETAIL' (state, payload) {
    state.donorDetail = payload
  },
  'TOGGLE_LOADING_DETAIL' (state, payload = false) {
    state.loadingDetail = !!payload
  },
  'SET_ORDER_PARAMS' (state, payload) {
    state.orderParams = payload
  },
  'SET_EDIT_FORM' (state, payload) {
    state.editForm = payload
  },
  'SET_COMERCIAL_PHONE' (state, payload) {
    state.comercialPhone = payload
  },
  'SET_RESIDENTIAL_PHONE' (state, payload) {
    state.residentialPhone = payload
  },
  'SET_MOBILE_PHONE' (state, payload) {
    state.mobilePhone = payload
  },
}

const actions = {

  setDonorsData ({ commit }, payload ) {
    commit('SET_DONORS_DATA', payload)
  },

  setMetaDataDonorsList ({ commit }, payload ) {
    commit('SET_METADATA_DONORS_LIST', payload)
  },

  setTotalDonors ({ commit }, payload ) {
    commit('SET_TOTAL_DONORS', payload)
  },

  setTotalActive ({ commit }, payload ) {
    commit('SET_TOTAL_ACTIVE', payload)
  },

  setTotalInactive ({ commit }, payload ) {
    commit('SET_TOTAL_INACTIVE', payload)
  },

  addItem ({ commit }, item) {
    if (item.filterBy && item.equalTo && item.fieldOne) {
      commit('ADD_ITEM', item)
    }
  },

  removeItem ({ commit }, item) {
    commit('REMOVE_ITEM', item)
  },

  clearFilterItems ( { commit } ) {
    commit('CLEAR_ITEMS')
  },

  clearFilterForm ( { commit } ) {
    commit('CLEAR_FILTER_FORM')
  },

  setDonorDetail ({ commit }, payload ) {
    commit('SET_DONOR_DETAIL', payload)
  },

  toggleLoadingDetail ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_DETAIL', isLoading)
  },

  setOrderParams ({ commit }, payload ) {
    commit('SET_ORDER_PARAMS', payload)
  },

  setEditForm ({ commit }, payload = false ) {
    commit('SET_EDIT_FORM', payload)
  },

  setComercialPhone ({ commit }, payload ) {
    commit('SET_COMERCIAL_PHONE', payload)
  },
  setResidentialPhone ({ commit }, payload ) {
    commit('SET_RESIDENTIAL_PHONE', payload)
  },
  setMobilePhone ({ commit }, payload ) {
    commit('SET_MOBILE_PHONE', payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
