import { getQueryFilesFinished } from './getFilesFinished'
import { getQueryFilesNewShipment } from './getFilesNewShipment'
import { getQueryFilesWaitingReturn } from './getFilesWaitingReturn'
import { getWalletsList } from './getWalletsList'

export {
    getQueryFilesFinished,
    getQueryFilesNewShipment,
    getQueryFilesWaitingReturn,
    getWalletsList
}
