import http, { API_PREFIX } from '../http'

export const oauth = params =>
  new Promise((resolve, reject) =>
    http.post(`${API_PREFIX}/oauth/token`, params)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)))

export const oauthInfo = (params = {}) =>
new Promise((resolve, reject) =>
  http.get(`${API_PREFIX}/oauth/token/info`, params)
    .then(({ data }) => resolve(data))
    .catch(err => reject(err)))

export default { oauth, oauthInfo }
