import gql from 'graphql-tag'

const query = gql`
    query processedOutputProcessRequests(
        $walletID: ID,
        $beginDate: ISO8601Date!,
        $endDate: ISO8601Date!,
        $page: Int = 1,
        $limit: Int = 10,
        $orderBy: String = "createdAt",
        $order: String = "desc") {
    processedOutputProcessRequests(
        walletId: $walletID,
        beginDate: $beginDate,
        endDate: $endDate,
        page: $page,
        limit: $limit,
        orderBy: $orderBy,
        order: $order
    ) {
        result {
            collection {
                bankCode bankName createdAt id paymentMethodLabel total
            }
            metadata {
                currentPage
                limitValue
                totalCount
                totalPages
            }
        }
    }
}`

/**
 *
 * @param {*} walletID
 * @param {*} beginDate
 * @param {*} endDate
 * @param {*} page
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 */
async function getQueryFilesFinished ( params = {} ) {
    const { $api } = Vue.prototype
    const result = await $api.query({
        query,
        fetchPolicy: 'no-cache',
        variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getQueryFilesFinished }
