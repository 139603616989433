const state = {
  items: []
}

const mutations = {
  'CHANGE_ITEMS' (state, payload) {
    state.items = payload
  }
}

const actions = {
  changeBreadcrumbItems ({ commit }, items = []) {
    const dashboard = [{
      to: { name: 'dashboard' },
      title: 'Início',
      active: false
    }]

    items = items.length > 0 ? [...dashboard, ...items] : items

    commit('CHANGE_ITEMS', items)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
