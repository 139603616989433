var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui tuim ui sortable selectable table" }, [
    _c("div", { staticClass: "thead" }, [
      _c(
        "div",
        { staticClass: "tr" },
        [
          _vm._l(_vm.thead, function(head, i) {
            return _c("div", {
              key: i,
              staticClass: "th",
              domProps: { innerHTML: _vm._s(head) }
            })
          }),
          _c("div", { staticClass: "th" }, [_vm._v(" ")])
        ],
        2
      )
    ]),
    _c(
      "div",
      { staticClass: "tbody" },
      [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "tr" },
              _vm._l(_vm.thead, function(item, k) {
                return _c(
                  "div",
                  { key: k, staticClass: "td" },
                  [_c("t-placeholder", { attrs: { lines: 1 } })],
                  1
                )
              }),
              0
            )
          : _vm._l(_vm.data, function(items, i) {
              return _c(
                "div",
                { key: i, staticClass: "tr" },
                [
                  _vm._l(items, function(item, k) {
                    return _c("div", {
                      key: k,
                      staticClass: "td",
                      domProps: { innerHTML: _vm._s(item) }
                    })
                  }),
                  _c(
                    "div",
                    { staticClass: "td center aligned" },
                    [_vm._t("actions")],
                    2
                  )
                ],
                2
              )
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }