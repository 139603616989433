<template>
  <div id="cohort" ref="cohort" />
</template>

<style lang="scss">
@import "collect/cohort";
</style>

<script>
import Cornelius from './cornelius'
import { getYear } from '@/helpers'

export default {
  name: 'TChortChart',
  props: {
    cohortData: {
      type: Array,
      default: () => []
    },
    initialDate: {
      type: Date,
      default: new Date(getYear(), 0)
    }
  },
  mounted () {
    Cornelius.setDefaults({
      labels: {
        time: 'Período',
        people: 'Doações'
      }
    })

    Cornelius.draw({
      initialDate: this.initialDate,
      container: this.$refs.cohort,
      cohort: this.cohortData
    })
  }
}
</script>
