
<template>
  <router-view />
</template>

<script>
export default {
  name: 'TMotorDeCobranca'
}
</script>

<style lang="scss" src="./app.scss"></style>
