import http, { API_PREFIX } from '../http'

// general_info: http://www.mocky.io/v2/5d38be1a9f0000344e9b40c9
export const getGeneralInfo = (params = {}) =>
  new Promise((resolve, reject) =>
    http.get(`${API_PREFIX}/general_info`, params)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)))

export default { getGeneralInfo }
