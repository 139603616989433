var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "inner-notifications " +
        (_vm.activeStatus ? "active" : "") +
        " " +
        _vm.notificationClass
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeStatus,
              expression: "activeStatus"
            }
          ],
          class:
            "ui container flex centered inner " +
            (_vm.activeStatus ? "active" : "")
        },
        [
          _c("div", { staticClass: "content" }, [
            _vm.icon
              ? _c(
                  "span",
                  { staticClass: "tui tuim icon" },
                  [_c("t-icon", { attrs: { icon: ["fas", _vm.icon] } })],
                  1
                )
              : _vm._e(),
            _vm.notificationMessage
              ? _c("span", {
                  staticClass: "info",
                  domProps: { innerHTML: _vm._s(_vm.notificationMessage) }
                })
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "close-toast",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closeNotification()
                }
              }
            },
            [_c("t-icon", { attrs: { icon: ["fas", "times"] } })],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }