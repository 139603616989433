export default {
  name: 'TProgressBar',
  props: {
    percent: {
      type: Number,
      default: 0
    },
    timer: {
      type: Number,
      default: 1000
    },
    label: {
      type: String,
      default: ''
    },
    indicating: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    },
    attached: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    udpateProgress (percent) {
      $('.tui.progress').progress({ percent })
    }
  },
  mounted () {
    let _progressCounter = this.timer / 1000
    let _progressSizeCountdown
    let _progressCountdown
    let _progressSize = 0

    const progressTimer = () => {
      _progressCounter--

      if (_progressCounter <= 0) {
        return clearInterval(_progressCountdown)
      }

      _progressSizeCountdown = setInterval(progressSize, 10)
    }

    const progressSize = () => {
      if (_progressSize >= 100) {
        return clearInterval(_progressSizeCountdown)
      }

      _progressSize++
      this.udpateProgress(_progressSize)
    }

    _progressCountdown = setInterval(progressTimer, 1000)
  }
}
