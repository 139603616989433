import Vue from 'vue'
import config, { isProduction, isStaging } from '@/config'
import { API_PREFIX } from '../services/http'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error';
import store from '@/store'

const logoutUser = () => {
  store.commit('SET_AUTH_STATUS', '')
  store.commit('RESET_AUTH_TOKEN')
  store.commit('SET_AUTH_USER', {})
}

const newTenant = localStorage.getItem('tenant')
let uriGraphiQl = `${config.collectApiUrl}/${API_PREFIX}/graphql`

if (isProduction) {
  uriGraphiQl = `https://${newTenant}.trackpay.trackmob.com.br/${API_PREFIX}/graphql`
} else if (isStaging) {
  uriGraphiQl = `https://${newTenant}.trackpay.trackmobdev.com.br/${API_PREFIX}/graphql`
}

Vue.use ({
  install (vue) {

    const httpLink = createHttpLink({
      uri: uriGraphiQl
    })

    const authLink = setContext((_, { headers }) => {
      const tokenAPI = localStorage.getItem('token')
      return {
        headers:{
          ...headers,
          Authorization: tokenAPI ? `Bearer ${tokenAPI}` : ''
        }
      }
    })

    const logoutLink = onError(({ networkError }) => {
      if (networkError && networkError.statusCode && networkError.statusCode === 401) {
        logoutUser()
      }
    })

    Vue.prototype.$api = new ApolloClient({
      link: authLink.concat(logoutLink).concat(httpLink),
      cache: new InMemoryCache()
    })
  }
})
