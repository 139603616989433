import auth from './auth.service'
import files from './files.service'
import transactions from './transactions.service'
import donations from './donations.service'

export default {
  auth,
  files,
  transactions,
  donations
}
