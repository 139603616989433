<template>
  <div class="tui tuim ui sortable selectable table">
    <div class="thead">
      <div class="tr">
        <div class="th" v-for="(head, i) in thead" :key="i" v-html="head" />
        <div class="th">&nbsp;</div>
      </div>
    </div>
    <div class="tbody">
      <div class="tr" v-if="loading">
        <div class="td" v-for="(item, k) in thead" :key="k">
          <t-placeholder :lines="1" />
        </div>
      </div>
      <div class="tr" v-else v-for="(items, i) in data" :key="i">
        <div class="td" v-for="(item, k) in items" :key="k" v-html="item" />
        <div class="td center aligned">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    thead: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  }

}
</script>

<style lang="scss">
@import "collect/table";

.icon-table {
  font-size: 16px;
  margin-right: 5px;
}

.color-error { color: #fd9093; }
.color-success { color: #b4db75; }
.color-pending { color: #d1d1d1; }
.color-processing { color: #f9d96a; }
.color-chargeback { color: #B489C4; }

.ui.sortable.table thead th.th {
  border-left: none;

  .icon-order {
    margin: 0 4px 3px 0px;
    color: #d1d1d1;
  }

  &.sorted {
    .icon-order {
      color: #333;
    }

    &.desc {
      .icon-order {
        transform: rotate(180deg) translateY(-5px);
      }
    }
  }
}

/* table with fixed header */
.ui.single.line.table.fixed-header {
  background: #fafafa;

  .headers {
    position: sticky;
    top: 80px;
    z-index: 1;
    background-color: #fff;
    color: #474747;
    font-size: 12px;
    font-weight: 600;
    padding: 15px 20px;
    text-transform: uppercase;
  }

  .columns {
    color: #7a7a7a;
    font-size: 14px;
    padding: 20px;
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
  }

  .extra-options {
    max-width: 20px;
  }
}

</style>