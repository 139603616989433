import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const dateTimeFormats = {
  'pt-br': {
    year: { year: 'numeric' },
    short: { day: 'numeric', month: 'numeric', year: 'numeric' },
    datetime: { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
    long: { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }
  },
  'en': {
    year: { year: 'numeric' },
    short: { year: 'numeric', month: 'numeric', day: 'numeric' },
    datetime: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' },
    long: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }
  }
}

const numberFormats = {
  'pt-br': {
    currency: {
      style: 'currency',
      currency: 'BRL',
      currencyDisplay: 'code',
      useGrouping: false
    }
  },
  'en': {
    currency: {
      style: 'currency',
      currency: 'USD'
      // currencyDisplay: '$'
    }
  }
}

const loadLocaleMessages = () => {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt-br',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-br',
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats
})
