import i18n from '@/config/i18n'
import currency from 'currency.js'
import { toUpperCase, splicePrice, getWholePercent } from './helpers'

/**
 * Use i18n for formate date time
 *
 * @param {String} stringDate
 * @param {String} opt='short'
 */
export const formatDate = (stringDate, opt = '') =>
  i18n.d(new Date(stringDate), opt)

/**
 *
 * @param {*} stringDate
 */
export const shortFormatDate = (stringDate) => {
  stringDate = stringDate.substr(0, 10)
  const _year = stringDate.split("-")[0]
  const _month = stringDate.split("-")[1]
  const _day = stringDate.split("-")[2]
  return i18n.d(new Date(_year, Number(_month) - 1, _day), 'short')
}
/**
 *
 * @param {*} stringDate
 */
export const dateTimeFormatDate = (stringDate) => {
  const strDate = stringDate.substr(0, stringDate.indexOf("T"))
  const strFormatedDate = shortFormatDate(strDate)
  const strTime = stringDate.substring(stringDate.indexOf("T") + 1, stringDate.indexOf("Z") - 3)
  return `${strFormatedDate} ${strTime}`
}

/**
 * Get status icon
 *
 * @param {String} status
 */
export const getStatusIcon = status => {
  let icon = ``

  switch (status) {
    case 'hard_bounce':
    case 'error':
    case 'canceled':
      icon = `times-circle status-color red-500` // red
      break

    case 'paid':
    case 'over_paid':
    case 'under_paid':
      icon = `check-circle status-color green-300` // green
      break

    case 'soft_bounce':
      icon = `minus-circle status-color yellow-300` // yellow
      break

    case 'refunded':
    case 'charged_back':
      icon = `exclamation-triangle status-color purple-300` // purple
      break

    case 'expired':
      icon = `exclamation-triangle status-color gray-500` // gray
      break

    case 'pending':
    case 'generated':
    default:
      icon = `sync status-color gray-300` // gray
      break
  }

  return `<i class="fas icon-table fa-${icon} icon icon-${status}"></i>`
}

export const getTransactionStatus = status => {
  let icon = ``

  switch (status) {
    case 'hard_bounce':
    case 'error':
    case 'canceled':
      icon = `circle status-color red-500` // red
      break

    case 'paid':
    case 'over_paid':
    case 'under_paid':
      icon = `circle status-color green-300` // green
      break

    case 'soft_bounce':
      icon = `circle status-color yellow-300` // yellow
      break

    case 'refunded':
    case 'charged_back':
      icon = `circle status-color purple-300` // purple
      break

    case 'expired':
      icon = `circle status-color gray-500` // gray
      break

    case 'pending':
    case 'generated':
    default:
      icon = `circle status-color gray-300` // gray
      break
  }

  return `<i class="fas fa-${icon} icon icon-${status}"></i>`
  // return (<t-icon :icon="icon" />)
}

/**
 * Format status
 *
 * @param {String} status
 * @param {String} text='pending'
 */
export const formatStatus = (status) =>
  `${getStatusIcon(status)} <span>${toUpperCase(i18n.t(`status.${status}`))}</span>`

export const formatStatusLabel = (status, statusLabel) =>
  `${getStatusIcon(status)} <span>${statusLabel.charAt(0).toUpperCase()}${statusLabel.slice(1)}</span>`

export const formatTransactionStatus = (status, statusLabel) =>
  `${getTransactionStatus(status)} <span>${statusLabel.charAt(0).toUpperCase()}${statusLabel.slice(1)}</span>`

/**
 * Use i18n for formate status
 *
 * @param {*} status
 */
export const getStatusLabel = (status) => i18n.t(`status.${status}`)

/**
 *
 * @param {*} status
 */
export const getDonorStatusIcon = status => {
  let icon = ``

  if (status === 'active') {
    icon = `circle color-active` // blue
  } else {
    icon = `circle color-pending` // gray
  }

  return `<i class="fas icon-table fa-${icon} icon icon-${status}"></i>`
}

/**
 *
 * @param {*} status
 * @param {*} statusLabel
 */
export const formatStatusDonors = (status, statusLabel) =>
  `<span class="donor-status">${getDonorStatusIcon(status)} ${statusLabel}</span>`

/**
 * @param  {Number} number
 * @param  {Object} currencySettings={}
 */
export const currencyFormat = (number, currencySettings = {}) => {
  const _settings = {
    ...{
      symbol: '',
      separator: '.',
      decimal: ',',
      precision: 2
    },
    ...currencySettings
  }

  const _number = splicePrice(number, -2, '.')
  // return i18n.n(_number, 'currency') // format currency using vue-i18n
  return currency(_number, _settings).format(true) // format currency using currency.js
}

export const formatValueCurrency = (value, currencyType = 'BRL') => {
  return `<span class="currency currency-value">${currencyFormat(value)}</span><span class="currency currency-type">${currencyType}</span>`
}

export const formatValueTaxes = (value, currencyType = 'BRL') => {
  return `<span class="currency currency-value red-500">${currencyFormat(value)}</span><span class="currency currency-type">${currencyType}</span>`
}

/**
 * @param  {Number} number
 * @param  {Number} total
 */
export const statusWidh = (number, total) => `${getWholePercent(number, total)}%`

/**
 * @param  {Number} number
 */
export const thousandsFormat = number =>
  currency(number, { formatWithSymbol: false, separator: '.', precision: 0 }).format()

/**
 * @param  {Number} number
 */
export const percentFormat = number =>
  (number > 0 ? `${number.toString().replace('.', ',')}%` : number)

export const formatStatusBankSlip = (statusOrDate) => {
  const date = Date.parse(statusOrDate)
  if (isNaN(date)) { // isString
    return `<span class="status pending"><i class="fas fa-circle icon"></i><span class="status-label">${statusOrDate}</span></span>`
  } else { // isDate
    return `<span class="status downloaded"><i class="fas fa-circle icon"></i><span class="status-label">${formatDate(statusOrDate)}</span></span>`
  }
}

/**
 *
 * @param {*} status
 */
export const getDonationStatusIcon = status => {
  let icon = ``

  if (status === 'active') {
    icon = `circle color active` // blue
  } else if (status === 'inactive') {
    icon = `circle color inactive` // red
  } else {
    icon = `circle color finalized` // green
  }

  return `<i class="fas icon-table fa-${icon} icon icon-${status}"></i>`
}

/**
 *
 * @param {*} status
 * @param {*} statusLabel
 */
export const formatStatusDonation = (status, statusLabel) =>
  `<span class="donation-status">${getDonationStatusIcon(status)} ${statusLabel}</span>`

/**
 * solve the issue where string date converted d -1
 * @param {*} strDate (yyyy-MM-dd)
 */
export const converShortStringDate = (stringDate) => {
  let date = new Date(stringDate)
  date.setDate(date.getDate() + 1)
  return date
}


/**
 *
 * @param {*} strDate (yyyy-MM-dd)
 * @param {*} options (Intl.DateTimeFormat options)
 */
export const intlFormatDate = (stringDate, options = {}) => {
  let date = converShortStringDate(stringDate)
  return new Intl.DateTimeFormat('pt-BR', options).format(date)
}

/**
 *
 * @param {*} strDate (yyyy-MM-dd)
 */
export const textFormatDate = (strDate) => {
  const options = { year: 'numeric', month: 'long' }
  const formatedDate = intlFormatDate(strDate, options)
  return formatedDate.replace(' de' , ' ').trim()
}

export const textFormatDateDay = (strDate) => {
  const options = { day: 'numeric', month: 'long' }
  const formatedDate = intlFormatDate(strDate, options)
  return formatedDate.trim()
}

export const getStatusLabelFile = (status, downloadedDate) => {
  if (['scheduled','pending'].includes(status)) {
    return `<div class="ui horizontal label">Gerando</div>`
  } else if (status === 'processed' && !downloadedDate) {
    return `<div class="ui teal horizontal label">Novo</div>`
  } else {
    return ''
  }
}
