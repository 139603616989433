import http, { API_PREFIX } from '../http'

export const getFileStatusByMonth = (params = {}) =>
  new Promise((resolve, reject) =>
    http.get(`${API_PREFIX}/monthly_metrics`, params)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)))

export const getFilesByMonth = (params = {}) =>
  new Promise((resolve, reject) =>
    http.get(`${API_PREFIX}/bank_files`, params)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)))

export default {
  getFileStatusByMonth,
  getFilesByMonth
}
