var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modalId
    ? _c(
        "div",
        { class: "tui tuim ui modal", attrs: { id: _vm.modalId } },
        [
          _vm.icon
            ? _c("t-icon", {
                staticClass: "icon",
                attrs: { icon: ["fas", _vm.icon] }
              })
            : _vm._e(),
          _vm.title
            ? _c("div", {
                staticClass: "header",
                domProps: { innerHTML: _vm._s(_vm.title) }
              })
            : _vm._e(),
          _vm.$slots.content
            ? _c(
                "div",
                {
                  class:
                    "scrolling " +
                    (_vm.$slots.image ? "image" : "") +
                    " content"
                },
                [
                  _vm.$slots.image
                    ? _c(
                        "div",
                        { staticClass: "ui medium image" },
                        [_vm._t("image")],
                        2
                      )
                    : _vm._e(),
                  _vm.$slots.content
                    ? _c(
                        "div",
                        { staticClass: "description" },
                        [_vm._t("content")],
                        2
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.$slots.actions
            ? _c("div", { staticClass: "actions" }, [_vm._t("actions")], 2)
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }