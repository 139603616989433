const state = {
  item: "",
  from: "" // grab from this.$route
}

const mutations = {
  'SET_ITEM' (state, payload) {
    state.item = payload
  }
}

const actions = {
  setItem ({ commit }, payload ) {
    commit('SET_ITEM', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
