<template>
  <div class="tui tuim ui container">
    <div class="tui tuim ui grid">
      <div :class="`tui tuim ui row ${customClassRow}`">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TPageMain',
   props: {
    customClassRow: {
      type: String,
      default: ''
    }
  }
}
</script>
