<template>
  <div v-if="modalId" :id="modalId" :class="`tui tuim ui modal`">
    <t-icon v-if="icon" :icon="['fas', icon]" class="icon" />
    <div class="header" v-if="title" v-html="title" />
    <div :class="`scrolling ${$slots.image ? 'image': ''} content`" v-if="$slots.content">
      <div class="ui medium image" v-if="$slots.image">
        <slot name="image" />
      </div>
      <div class="description" v-if="$slots.content">
        <slot name="content" />
      </div>
    </div>
    <div class="actions" v-if="$slots.actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TModals',
  props: {
    modalId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'times'
    }
  }
}
</script>
