import http, { API_PREFIX } from '../http'

export const getTotalDonations = (params = {}) =>
  new Promise((resolve, reject) =>
    http.get(`${API_PREFIX}/total_donations`, params)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)))

export const getRecurrenceFrictionRate = (params = {}) =>
  new Promise((resolve, reject) =>
    http.get(`${API_PREFIX}/cohort_data`, params)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)))

export default {
  getTotalDonations,
  getRecurrenceFrictionRate
}
