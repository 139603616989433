export default {
  name: 'TPlaceholder',
  props: {
    lines: {
      type: Number,
      default: 4
    },
    fluid: {
      type: Boolean,
      default: true
    },
    header: {
      type: Boolean,
      default: false
    },
    paragraph: {
      type: Boolean,
      default: true
    },
    image: {
      type: Boolean,
      default: false
    }
  }
}
