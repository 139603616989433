export default {
  name: 'auth',
  path: '/auth',
  component: () => import('@/layouts/auth.vue'),
  redirect: { name: 'auth.login' },
  children: [
    {
      name: 'auth.login',
      path: '/login',
      component: () => import('@/pages/auth/login')
    },
    {
      name: 'auth.password',
      path: '/password',
      redirect: { name: 'auth.password.new' }
    },
    {
      name: 'auth.password.new',
      path: '/password/new',
      component: () => import('@/pages/auth/password/new')
    },
    {
      name: 'auth.password.edit',
      path: '/password/edit',
      component: () => import('@/pages/auth/password/edit')
    }
  ]
}
