/**
 * @param {String} value
 */
export const toString = value =>
  (!value) ? '' : value.toString()

/**
 * @param {String} str
 */
export const toUpperCase = str =>
  toString(str.toUpperCase())

/**
 * @param {String} str
 */
export const toCapitalize = str =>
  toString(toUpperCase(str.charAt(0)) + str.slice(1))

/**
 * @param {String} str
 * @param {String} separator='-'
 */
export const slugfy = (str, separator = '-') =>
  str.toString().toLowerCase()
    .replace(/\s+/g, separator)
    .replace(/[^\w\-]+/g, '') // eslint-disable-line
    .replace(/\-\-+/g, separator) // eslint-disable-line
    .replace(/^-+/, '')
    .replace(/-+$/, '')

/**
 * @param {String} year
 * @param {} now=new Date(Date.now())
 */
export const getYear = (year, now = new Date(Date.now())) =>
  (typeof year !== 'undefined') ? year : now.getFullYear()

/**
 * @param {String} month
 * @param {} now=new Date(Date.now())
 */
export const getMonth = (month, now = new Date(Date.now())) =>
  (typeof month !== 'undefined') ? month : now.getMonth() + 1

/**
 * @param  {String} str
 * @param  {Number} index=-2
 * @param  {String} replace='.'
 */
export const splicePrice = (str, index = -2, replace = '.') => {
  if (!str) { return }
  const _str = toString(str)
  const _i = index < 0 ? _str.length + index : index
  const _number = `${_str.substring(0, _i)}${replace}${_str.substring(_i, _str.length)}`
  return parseFloat(_number)
}

export const unmaskMoney = ( str ) => {
  if (!str) { return }
  const _str = toString(str)
  const _number = _str.replace('R$', '').replace('.', '').replace(',', '').trim()
  return Number(_number)
}

/**
 * @param  {Number} percentFor
 * @param  {Number} percentOf
 */
export const getWholePercent = (percentFor, percentOf) =>
  (percentFor / percentOf * 100)

/**
 *
 * @param {String} date
 */
export const checkDate = date => {
  if (!date) return
  const reg = /^(0[1-9]|[12]\d|3[01])[\/](0[1-9]|1[0-2])[\/](19|20)\d{2}$/g  // eslint-disable-line
  return !date.match(reg)
}

export const getBRTimeNow = () => {
  const offset = '-3'
  const d = new Date();

  const utc = d.getTime() + (d.getTimezoneOffset() * 60000);

  const nd = new Date(utc + (3600000*offset));

  const brtNow = nd.toLocaleString()
  const timeNow = brtNow.substring(brtNow.indexOf(" "), brtNow.length - 3);
  return timeNow.trim();
}

export const currentYear = () => {
  const now = new Date(Date.now())
  return now.getFullYear()
}

export const currentMonth = () => {
  const now = new Date(Date.now())
  return now.getMonth() + 1
}
