var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "tui tuim ui " +
        _vm.size +
        " wide column page-content " +
        _vm.customClass,
      attrs: { id: "page-content" }
    },
    [_c("div", { staticClass: "tui tuim inner" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }