<template>
  <div class="tui table">
    <table
      class="ui basic table ordered-table">
      <thead>
        <tr>
          <th
            class="table-header"
            v-for="(head, i) in headers"
            :key="i"
            :class="head.classes"
            @click.prevent="orderTable(head.key, head.order)"
          >
            <i
              v-if="sortable"
              :class="
                `fas order-icon ${
                  head.order === 'desc' ? 'fa-caret-down' : head.order === 'asc' ? 'fa-caret-up' : ''
                }`
              "
            ></i>
            &nbsp;
            <span class="title" v-text="head.title" />
          </th>
          <th v-if="$scopedSlots.actions" class="table-header" v-html="'&nbsp;'" />
        </tr>
      </thead>
      <tbody class="basic-table-body">
        <tr v-if="isLoading">
          <td v-for="(item, k) in [...Array(headerSize)]" :key="k">
            <t-placeholder :lines="1" />
          </td>
        </tr>
        <tr
          v-else
          v-for="(items, i) in dataList"
          :key="i"
          :class="{ 'clickable': routeParams.clickToView }"
        >
          <template v-if="routeParams.clickToView">
            <router-link
              tag="td"
              class="columns"
              v-for="(item, k) in dataFormatter ? dataFormatter(items) : items"
              :to="{
                name: routeParams.routeName,
                params: routeParams.params ? resolveRouteParams(dataList[i]) : { id: itemId(dataList[i]) }
              }"
              :key="k"
              v-html="item"
              @mouseover.native="setItemFromEmiter(dataList[i])"
              @click.native="setItemFrom(dataList[i])">
            </router-link>
          </template>
          <template v-else>
            <td
              class="columns"
              v-for="(item, k) in dataFormatter ? dataFormatter(items) : items"
              :key="k"
              v-html="item"
            />
          </template>
          <td v-if="$scopedSlots.actions">
            <slot name="actions" v-bind="dataList[i]" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TuiTable',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    },
    dataList: {
      type: Array,
      required: true,
      default: () => []
    },
    dataFormatter: {
      type: Function
    },
    routeParams: {
      type: Object,
      default: () => {
        return {
          routeName: null,
          clickToView: false,
          params: null // { path, param }
        }
      }
    }
  },

  computed: {
    headerSize() {
      return this.headers ? this.headers.length : 0
    }
  },

  methods: {
    ...mapActions('table', [ 'setItem' ]),

    itemId: item => item.id,

    resolveRouteParams(data) {
      let routeObject = {}
      this.routeParams.params.forEach(routeParam => {
        routeObject = {...routeObject}
        routeObject[routeParam.path] = data[routeParam.param]
      })
      return routeObject
    },

    orderTable(field, order) {
      if (this.sortable) {
        const header = this.headers.find(head => head.key === field)
        if (!order || order === 'asc') {
          order = 'desc'
        } else {
          order = 'asc'
        }
        header.order = order
        if (!header.classes.includes('active')) {
          if (!header.classes) {
            header.classes = 'active'
          } else {
            header.classes = header.classes.trim().concat(' active')
          }
        }

        this.headers.forEach(head => {
          if (head.key !== field) {
            head.order = null
            head.classes = head.classes.replaceAll('active', '').trim()
          }
        })

        this.$emit('orderBy', { field, order })
      }
    },

    setItemFrom(data) {
      this.setItem(data)
    },

    setItemFromEmiter(data) {
      this.$emit('setItemEmit', data)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/status-colors";
@import "@/assets/sass/ordered-table";
</style>
