const state = {
  donationsData: [],
  metaDataDonationsList: {},
  isLoading: false,
  donationDetail: {},
  loadingDetail: false,
  filterForm: {},
  filterItems: [],
  orderParams: {}
}

const mutations = {
  'SET_DONATIONS_DATA' (state, payload) {
    state.donationsData = payload
  },
  'SET_METADATA_DONATIONS_LIST' (state, payload) {
    state.metaDataDonationsList = payload
  },
  'ADD_ITEM' (state, item) {
    state.filterItems.push(item)
  },
  'REMOVE_ITEM' (state, item) {
    const { filterItems } = state
    filterItems.splice(filterItems.indexOf(item), 1)
  },
  'CLEAR_ITEMS' (state) {
    state.filterItems = []
  },
  'CLEAR_FILTER_FORM' (state) {
    state.filterForm = {}
  },
  'SET_DONATION_DETAIL' (state, payload) {
    state.donationDetail = payload
  },
  'TOGGLE_LOADING_DETAIL' (state, payload = false) {
    state.loadingDetail = !!payload
  },
  'TOGGLE_LOADING_LIST' (state, payload = false) {
    state.isLoading = !!payload
  },
  'SET_ORDER_PARAMS' (state, payload) {
    state.orderParams = payload
  },
}

const actions = {

  setDonationsData ({ commit }, payload ) {
    commit('SET_DONATIONS_DATA', payload)
  },

  setMetaDataDonationsList ({ commit }, payload ) {
    commit('SET_METADATA_DONATIONS_LIST', payload)
  },

  addItem ({ commit }, item) {
    if (item.filterBy && item.equalTo && item.fieldOne) {
      commit('ADD_ITEM', item)
    }
  },

  removeItem ({ commit }, item) {
    commit('REMOVE_ITEM', item)
  },

  clearFilterItems ( { commit } ) {
    commit('CLEAR_ITEMS')
  },

  clearFilterForm ( { commit } ) {
    commit('CLEAR_FILTER_FORM')
  },

  setDonationDetail ({ commit }, payload ) {
    commit('SET_DONATION_DETAIL', payload)
  },

  toggleLoadingDetail ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_DETAIL', isLoading)
  },

  toggleLoadingList ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_LIST', isLoading)
  },

  setOrderParams ({ commit }, payload ) {
    commit('SET_ORDER_PARAMS', payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
