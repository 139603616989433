import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

const defaultData = {}

const defaultOptions = {
  maintainAspectRatio: false,
  legend: { display: false },
  responsive: true,
  title: { display: false, },
  scales: {
    xAxes: [{
      stacked: true,
      barPercentage: 0.3
    }],
    yAxes: [{
      stacked: true
    }]
  },
  tooltips: {
    callbacks: { label: tooltip => tooltip.yLabel }
  },
  layout: { padding: 0 }
}

export default {
  extends: Bar,
  mixins: [reactiveProp],
  name: 'TBarChart',
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },
    chartData: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    const options = { ...defaultOptions, ...this.options }
    const data = { ...defaultData, ...this.chartData }
    this.renderChart(data, options)
  }
}
