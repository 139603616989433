var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui tuim ui container" }, [
    _c("div", { staticClass: "tui tuim ui grid" }, [
      _c(
        "div",
        { class: "tui tuim ui row " + _vm.customClassRow },
        [_vm._t("default")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }