const state = {
  active: false,
  type: '',
  notificationMessage: '',
  types: [
    { type: 'success',    class: 'success' },
    { type: 'progress',   class: 'in-progress' },
    { type: 'error',      class: 'error' },
    { type: 'processing', class: 'processing' }
  ]
}

const mutations = {
  'TOGGLE_ACTIVE_NOTIFICATION' (state, payload) {
    state.active = payload.active
    state.type = payload.type ? state.types.find( type => type.type === payload.type ) : ''
  },
  'SET_NOTIFICATION_MESSAGE' (state, payload) {
    state.notificationMessage = payload
  }
}

const actions = {
  toggleNotification ({ commit }, payload) {
    if (!payload) {
      payload = { active: false }
      state.notificationMessage = ''
    }
    commit('TOGGLE_ACTIVE_NOTIFICATION', payload)
  },

  setNotificationMessage ({ commit }, payload) {
    commit('SET_NOTIFICATION_MESSAGE', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
