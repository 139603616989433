var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tui tuim ui container" }, [
      _c("div", { staticClass: "tui tuim ui grid centered" }, [
        _c("div", { staticClass: "tui tuim ui twelve wide column" }, [
          _c("div", { staticClass: "tui tuim ui grid" }, [
            _c("div", { staticClass: "tui tuim row" }, [
              _c("div", { staticClass: "tui tuim ui six wide column" }, [
                _c("div", { staticClass: "wrap-step-by-step fist-item" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("h3", { staticClass: "label" }, [_vm._v("Pertence a")]),
                    _c("span", { staticClass: "text" }, [
                      _vm._v("Anna Lethicia Tavares Martinelli Manfredini...")
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "tui tuim ui five wide column" }, [
                _c("div", { staticClass: "wrap-step-by-step" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("h3", { staticClass: "label" }, [_vm._v("Doação")]),
                    _c("span", { staticClass: "text" }, [
                      _vm._v("BRL "),
                      _c("b", [_vm._v("30,00")]),
                      _vm._v(" mensais")
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "tui tuim ui five wide column" }, [
                _c("div", { staticClass: "wrap-step-by-step last-item" }, [
                  _c("a", { attrs: { href: "" } }, [
                    _c("h3", { staticClass: "label" }, [_vm._v("Cobrança")]),
                    _c("span", { staticClass: "text" }, [
                      _vm._v("Janeiro 2019")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }