import axios from 'axios'
import NProgress from 'nprogress'
import config, { isProduction } from '@/config'

export const API_PREFIX = 'v1'
const token = localStorage.getItem('token')
let newTenant = localStorage.getItem('tenant')

if (!newTenant) {
  newTenant = window.location.host.split('.')[0]
  localStorage.setItem('tenant', newTenant)
}

// create a new axios instance
const http = axios.create({
  baseURL: config.collectBaseUrl,
  headers: {
    Authorization: token ? `Bearer ${token}` : ''
  }
})

// before a request is made start the nprogress
http.interceptors.request.use(config => {
  if (!isProduction) {
    Vue.$log.info('request', config)
  }

  NProgress.start()
  return config
})

// before a response is returned stop nprogress
http.interceptors.response.use(response => {
  if (!isProduction) {
    Vue.$log.info('response', response)
  }

  NProgress.done()
  return response
})

export default http
