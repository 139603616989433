import Vue from 'vue'
import jQuery from 'jquery'
import TrackUI from '@trackmob/trackui'

import VuePageTitle from 'vue-page-title'
import VueLogger from 'vuejs-logger'
import Vuelidate from 'vuelidate'
import FileUpload from 'v-file-upload'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import VueGtag from "vue-gtag";

import FontAwesome from './plugins/fontawesome'
import './plugins/registerServiceWorker'
import './plugins/webfonts'
import './helpers/filters'
import './plugins/graphql'

import config, { isProduction, isStaging, gaID } from '@/config'
import i18n from '@/config/i18n'
import router, { checkExpiredToken } from '@/router'
import store from '@/store'
import http from '@/services/http'

import App from '@/layouts/app.vue'
import TPreloader from '@/components/preloader'
import TPlaceholder from '@/components/placeholder'
import TPageHeader from '@/components/page/header.vue'
import TPageMain from '@/components/page/main.vue'
import TPageContent from '@/components/page/content.vue'
import TPageSidebar from '@/components/page/sidebar.vue'
import TTable from '@/components/table'
import TAlert from '@/components/alert'
import TProgressBar from '@/components/progress/bar.vue'
import TDropdown from '@/components/dropdown'
import TLineChart from '@/components/chart/line'
import TBarChart from '@/components/chart/bar'
import TChortChart from '@/components/chart/cohort'
import TModal from '@/components/modals'
import TPagination from '@/components/pagination'
import TBreadcrumbStep from '@/components/breadcrumb/step-by-step.vue'
import TTransactionsSummaryCard from '@/components/transactions/transactions-summary-card.vue'
import TCardFormBilling from '@/components/transactions/card-form-billing.vue'
import TCardFormTransactionDetails from '@/components/transactions/card-form-transaction-details.vue'
import TNoData from '@/components/nodata/index.vue'
import TNotification from '@/components/notification/index.vue'
import TuiTable from '@/components/tui-table/index.vue'

window.Vue = Vue
window.faker = require('faker') // REMOVE IT

try {
  window.$ = window.jQuery = jQuery
} catch (e) { console.error(e) }

window.Tui = TrackUI
require('semantic-ui-css/semantic')

Vue.prototype.$http = http

Vue.use(VuePageTitle, config.pageTitle)
Vue.use(VueLogger, config.logger)
Vue.use(Vuelidate)
Vue.use(FileUpload)
Vue.use(VueTheMask)
Vue.use(money, { precision: 2 })

Vue.component('TIcon', FontAwesome)
Vue.component('TPreloader', TPreloader)
Vue.component('TPlaceholder', TPlaceholder)
Vue.component('TPageMain', TPageMain)
Vue.component('TPageContent', TPageContent)
Vue.component('TPageHeader', TPageHeader)
Vue.component('TPageSidebar', TPageSidebar)
Vue.component('TTable', TTable)
Vue.component('TAlert', TAlert)
Vue.component('TProgressBar', TProgressBar)
Vue.component('TDropdown', TDropdown)
Vue.component('TLineChart', TLineChart)
Vue.component('TBarChart', TBarChart)
Vue.component('TChortChart', TChortChart)
Vue.component('TModal', TModal)
Vue.component('TPagination', TPagination)
Vue.component('TBreadcrumbStep', TBreadcrumbStep)
Vue.component('TTransactionsSummaryCard', TTransactionsSummaryCard)
Vue.component('TCardFormBilling', TCardFormBilling)
Vue.component('TCardFormTransactionDetails', TCardFormTransactionDetails)
Vue.component('TNoData', TNoData)
Vue.component('TNotification', TNotification)
Vue.component('TuiTable', TuiTable)

Vue.config.productionTip = false
Vue.config.performance = !isProduction

if (isProduction || isStaging) {
  Vue.use(VueGtag, {
    config: { id: gaID },
    appName: 'Trackmob Collect',
    pageTrackerScreenviewEnabled: true
  }, router);
}

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
  created: checkExpiredToken
}).$mount('#app')
