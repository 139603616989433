var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap-pagination" }, [
    _c("div", { staticClass: "wrap-drop" }, [
      _c("div", { ref: "pageInput", staticClass: "tui ui input" }, [
        _c("input", {
          staticClass: "current-page",
          attrs: { type: "number", name: "pagination", maxlength: "2" },
          domProps: { value: _vm.currentPage },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.validatePage($event.target.value)
            }
          }
        }),
        _c("span", { staticClass: "text total-pages" }, [
          _vm._v("de " + _vm._s(_vm.totalPages))
        ])
      ])
    ]),
    _c("div", { staticClass: "ui basic buttons" }, [
      _c(
        "button",
        { staticClass: "ui icon button", on: { click: _vm.btnClickPrev } },
        [_c("t-icon", { attrs: { icon: ["far", "angle-left"] } })],
        1
      ),
      _c(
        "button",
        { staticClass: "ui icon button", on: { click: _vm.btnClickNext } },
        [_c("t-icon", { attrs: { icon: ["far", "angle-right"] } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }