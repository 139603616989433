<template>
  <div :class="`inner-notifications ${activeStatus ? 'active' : ''} ${notificationClass}`">
    <div v-show="activeStatus" :class="`ui container flex centered inner ${activeStatus ? 'active' : ''}`">
      <div class="content">
        <span v-if="icon" class="tui tuim icon">
          <t-icon :icon="[ 'fas', icon ]" />
        </span>
        <span v-if="notificationMessage" class="info" v-html="notificationMessage" />
      </div>
      <div class="close-toast" @click.prevent="closeNotification()">
        <t-icon :icon="['fas', 'times']" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "TNotification",

  computed: {
    ...mapState({
      activeStatus: ({ notifications }) => notifications.active,
      notificationClass: ({ notifications }) => notifications.type.class,
      icon: ({ notifications }) => {
        if (notifications.type.class === 'success') {
          return 'check'
        } else if (notifications.type.class === 'error') {
          return 'times-circle'
        } else if (notifications.type.class === 'processing') {
          return 'spinner'
        } else {
          return 'sync-alt'
        }
      },
      notificationMessage: ({ notifications }) => {
        if (!notifications.notificationMessage) {
          if (notifications.type.type === 'success') {
            return 'Operação realizada com sucesso!'
          } else if (notifications.type.type === 'error') {
            return 'Operação não realizada! Contate o suporte!'
          } else if (notifications.type.type === 'progress') {
            return 'Em Progresso...'
          }
        } else {
          return notifications.notificationMessage
        }
      }
    })
  },

  methods: {
    ...mapActions('notifications', [ 'toggleNotification' ]),

    closeNotification () {
      this.toggleNotification()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/colors';

.notifications {
  width: 100%;
  min-width: 100%;

  .inner-notifications {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    width: 100%;
    min-width: 100%;
    min-height: 0;
    border-top: none;
    overflow: hidden;
    transition: all ease 1s;

    .ui.container.flex.centered.inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        .icon {
          font-size: 14px;
          color: $white;
        }
      }
    }

    &.active {
      border-top: solid 1px $white-500;
      min-height: 60px;
    }

    &.in-progress { background-color: $positive-4; }
    &.success { background-color: $positive-3; }
    &.error { background-color: $negative-1; }
    &.processing { background-color: $green-500; }

    .info {
      margin-left: 10px;
      font-size: 16px;
      line-height: 23px;
      transition: all .5s ease-out;
    }

    .close-toast {
      right: 0;
      cursor: pointer;

      padding: 10px 12px;
      border-radius: 3px;

      &:hover {
        backdrop-filter: brightness(85%);
      }
    }
  }
}
</style>
