const state = {
  isVisible: false,
  timer: 0,
  icon: 'check',
  type: 'positive',
  message: ''
}

const mutations = {
  'TOGGLE_VISIBILITY' (state, payload = false) {
    state.isVisible = !!payload
  },
  'CHANGE_TIMER' (state, payload = 0) {
    state.timer = payload
  },
  'CHANGE_ICON' (state, payload = 'check') {
    state.icon = payload
  },
  'CHANGE_TYPE' (state, payload = 'positive') {
    state.type = payload
  },
  'CHANGE_MESSAGE' (state, payload = '') {
    state.message = payload
  }
}

const actions = {
  showAlert ({ commit }) {
    commit('TOGGLE_VISIBILITY', true)
  },
  hideAlert ({ commit }, timer = 0) {
    setTimeout(() => {
      commit('TOGGLE_VISIBILITY', false)
      commit('CHANGE_MESSAGE', '')
    }, timer)
  },
  changeTimer ({ commit }, timer) {
    commit('CHANGE_TIMER', timer)
  },
  changeIcon ({ commit }, icon) {
    commit('CHANGE_ICON', icon)
  },
  changeType ({ commit }, type) {
    commit('CHANGE_TYPE', type)
  },
  changeMessage ({ commit }, message) {
    commit('CHANGE_MESSAGE', message)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
