var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "tui tuim ui " +
        (_vm.indicating ? "indicating" : "") +
        " " +
        (_vm.attached ? "bottom attached" : "") +
        " progress"
    },
    [
      _c("div", { staticClass: "bar" }, [
        _vm.progress ? _c("div", { staticClass: "progress" }) : _vm._e()
      ]),
      _vm.label
        ? _c("div", {
            staticClass: "label",
            domProps: { innerHTML: _vm._s(_vm.label) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }