const state = {
  totalSelected: 0,
  months: [
    {value: 1, name: "Janeiro"},
    {value: 2, name: "Fevereiro"},
    {value: 3, name: "Março"},
    {value: 4, name: "Abril"},
    {value: 5, name: "Maio"},
    {value: 6, name: "Junho"},
    {value: 7, name: "Julho"},
    {value: 8, name: "Agosto"},
    {value: 9, name: "Setembro"},
    {value: 10, name: "Outubro"},
    {value: 11, name: "Novembro"},
    {value: 12, name: "Dezembro"}
  ],
  years: ['2020', '2019', '2018', '2017']
}

const mutations = {
  'SET_TOTAL_SELECTED' (state, payload) {
    state.totalSelected = payload
  }
}

const actions = {
  setTotalSelected ({ commit }, payload ) {
    commit('SET_TOTAL_SELECTED', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
