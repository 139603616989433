<template>
  <header class="tui tuim page-header">
    <div class="tui tuim ui container flex centered">
      <div class="tui tuim ui row">
        <div class="tui tuim ui middle aligned grid">
          <template v-if="actionsLeftAligned">
            <div v-if="$slots.actions" :class="`tui tuim ui floated left aligned ${rightSize} wide column`">
              <slot name="actions" />
            </div>
            <div :class="`tui floated right ${leftSize} wide column`">
              <div class="header-info">
                <h1 class="tui tuim ui huge header">
                  <span class="title" v-html="title" />
                  <span v-if="popup" class="tui popup" :data-html="popup">
                    <t-icon :icon="[ 'far', 'question-circle' ]" />
                  </span>
                </h1>
                <slot name="innerLeftActions" />
              </div>
              <div class="sub header" v-if="$slots.description">
                <slot name="description" />
              </div>
            </div>
          </template>
          <template v-else>
            <div :class="`tui floated left ${leftSize} wide column`">
              <h1 class="tui tuim ui huge header">
                <span v-html="title" />
                <span v-if="popup" class="tui popup" :data-html="popup">
                  <t-icon :icon="[ 'far', 'question-circle' ]" />
                </span>
              </h1>
              <div class="sub header" v-if="$slots.description">
                <slot name="description" />
              </div>
            </div>
            <div v-if="$slots.actions" :class="`tui tuim ui floated right aligned right ${rightSize} wide column`">
              <slot name="actions" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <slot name="bottom" />
    <div v-if="$slots.notifications" class="ui container flex centered notifications">
      <slot name="notifications" />
    </div>
  </header>
</template>

<script>

export default {
  name: 'TPageHeader',
  props: {
    title: {
      type: String,
      default: 'Dashboard'
    },
    description: {
      type: String,
      default: ''
    },
    popup: {
      type: String,
      default: ''
    },
    leftSize: {
      type: String,
      default: 'ten'
    },
    rightSize: {
      type: String,
      default: 'six'
    },
    actionsLeftAligned: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import "collect/page-header";
@import '@/assets/sass/colors';

.header-info {
  display: flex;
  justify-content: space-between;
}

.tui.tuim.ui.header {

  .title {
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .tui.popup {
    margin-left: 10px;
    position: relative;
    top: -4px;
    cursor: help;

    svg {
      width: 18px;
      height: 18px;
      color: #d1d1d1;
    }
  }
}

.ui.basic.button {
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  text-align: center;
  margin: 0px;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: none;
  }

  &.go-back {
    width: 40px;
    height: 40px;
  }

  .icon {
    font-size: 16px;
    line-height: 12px;
    font-weight: 700;
  }
}
</style>
