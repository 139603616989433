import Vue from 'vue'
import {
  slugfy,
  formatDate,
  formatStatus
} from '@/helpers'

Vue.filter('slugfy', slugfy)
Vue.filter('formatDate', formatDate)
Vue.filter('formatStatus', formatStatus)
