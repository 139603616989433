export default {
  path: '*',
  name: 'errors',
  component: () => import('@/layouts/errors'),
  children: [
    {
      path: '/403',
      name: 'errors.403',
      component: () => import('@/pages/errors/403.vue')
    },
    {
      path: '/502',
      name: 'errors.502',
      component: () => import('@/pages/errors/502.vue')
    },
    {
      path: '*',
      name: 'errors.404',
      component: () => import('@/pages/errors/404.vue')
    }
  ]
}
