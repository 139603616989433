import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'TLineChart',
  extends: Line,
  mixins: [reactiveProp],
  data () {
    return {
      defaultData: {},
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: {
          callbacks: { label: tooltip => tooltip.yLabel }
        },
        layout: { padding: 0 }
      }
    }
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    const options = { ...this.defaultOptions, ...this.options }
    const data = { ...this.defaultData, ...this.chartData }
    this.renderChart(data, options)
  }
}
