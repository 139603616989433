import gql from 'graphql-tag'

const query = gql `
    query pendingOutputProcessRequests(
        $walletID: ID,
        $beginDate: ISO8601Date!,
        $endDate: ISO8601Date!,
        $page: Int = 1,
        $limit: Int = 5,
        $orderBy: String = "createdAt",
        $order: String = "desc") {
    pendingOutputProcessRequests(
        walletId: $walletID,
        beginDate: $beginDate,
        endDate: $endDate,
        page: $page,
        limit: $limit,
        orderBy: $orderBy,
        order: $order
    ) {
        result {
            collection {
                bankCode
                bankName
                createdAt
                id
                paymentMethodLabel
                pending
                total
                outputFileUrl
                statusLabel
                status
                downloadedAt
            }
            metadata {
                currentPage
                limitValue
                totalCount
                totalPages
            }
        }
    }
}`

/**
 *
 * @param {*} walletID
 * @param {*} beginDate
 * @param {*} endDate
 * @param {*} page
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 */
async function getQueryFilesWaitingReturn(params = {}) {
    const { $api } = Vue.prototype
    const result = await $api.query({
            query,
            fetchPolicy: 'no-cache',
            variables: params
        }).then(res => res)
        .catch(e => e)
    return result;
}

export { getQueryFilesWaitingReturn }
