const state = {
  navItems: [],
}

const mutations = {
  'SET_NAV_ITEMS' (state, payload) {
    state.navItems = payload
  }
}

const actions = {
  setNavItems ({ commit }, payload) {
    commit('SET_NAV_ITEMS', payload)
  }
}

const getters = {
  getNavItems: state => state.navItems
}

export default {
  state,
  mutations,
  actions,
  getters
}
