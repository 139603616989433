import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import config from './config'
import store from './store'
import services from '@/services'

const { trackpay } = services

Vue.use(VueRouter)

const router = new VueRouter(config.router)

NProgress.configure({
  easing: 'ease',
  speed: 300,
  trickle: false,
  showSpinner: true
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  const { path } = to

  if (path) {
    let urlDownloadFile = path.match(/^\/downloads\/transactions\/(.)+$/g) // eslint-disable-line
    if (urlDownloadFile) {
      localStorage.setItem('url_transaction_file_download', urlDownloadFile[0])
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {

      if (store.getters.isDirectMail) {
        if (to.matched.some(record => record.meta.comingSoon) || to.name === 'files' && !store.getters.hasDebitAccount) {
          next({ name: 'transactions' })
        } else {
          next()
        }
      }

      next()
      // return
    } else {
      next({ name: 'auth.login' })
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }

  next()
})

const bodyClass = name => {
  const _name = name.toLowerCase().replace('.', '-')
  return `page-${_name}`
}

router.afterEach((to, from) => {
  const _body = document.getElementsByTagName('body')[0]

  if (typeof from !== 'undefined' && from.name !== null) {
    _body.classList.remove(bodyClass(from.name))
    _body.classList.remove(bodyClass(`coming-soon`))
  }

  _body.classList.add(bodyClass(to.name.toLowerCase()))

  if (to.meta.comingSoon) {
    _body.classList.add(bodyClass(`coming-soon`))
  }

  store.dispatch('comingSoon/showComingSoon', to.meta.comingSoon)
  store.dispatch('notifications/toggleNotification')

  checkExpiredLogin()

  NProgress.done()
})

const checkExpiredLogin = () => {
  const token = localStorage.getItem('token')
  const config = {
    headers:{
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
  return trackpay.auth.oauthInfo(config)
    .then(res => res)
    .catch(e => e)
}

export const checkExpiredToken = function () {
  this.$http.interceptors.response.use(undefined, function (err) {

    const { response } = err

    return new Promise(() => {
      if (response.status === 401 && response.data.error === 'invalid_token') {
        store.dispatch('logout')
      }

      throw err
    })
  })
}

export default router
