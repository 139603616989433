var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "tui tuim page-header" },
    [
      _c("div", { staticClass: "tui tuim ui container flex centered" }, [
        _c("div", { staticClass: "tui tuim ui row" }, [
          _c(
            "div",
            { staticClass: "tui tuim ui middle aligned grid" },
            [
              _vm.actionsLeftAligned
                ? [
                    _vm.$slots.actions
                      ? _c(
                          "div",
                          {
                            class:
                              "tui tuim ui floated left aligned " +
                              _vm.rightSize +
                              " wide column"
                          },
                          [_vm._t("actions")],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        class:
                          "tui floated right " + _vm.leftSize + " wide column"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "header-info" },
                          [
                            _c(
                              "h1",
                              { staticClass: "tui tuim ui huge header" },
                              [
                                _c("span", {
                                  staticClass: "title",
                                  domProps: { innerHTML: _vm._s(_vm.title) }
                                }),
                                _vm.popup
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tui popup",
                                        attrs: { "data-html": _vm.popup }
                                      },
                                      [
                                        _c("t-icon", {
                                          attrs: {
                                            icon: ["far", "question-circle"]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._t("innerLeftActions")
                          ],
                          2
                        ),
                        _vm.$slots.description
                          ? _c(
                              "div",
                              { staticClass: "sub header" },
                              [_vm._t("description")],
                              2
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                : [
                    _c(
                      "div",
                      {
                        class:
                          "tui floated left " + _vm.leftSize + " wide column"
                      },
                      [
                        _c("h1", { staticClass: "tui tuim ui huge header" }, [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.title) }
                          }),
                          _vm.popup
                            ? _c(
                                "span",
                                {
                                  staticClass: "tui popup",
                                  attrs: { "data-html": _vm.popup }
                                },
                                [
                                  _c("t-icon", {
                                    attrs: { icon: ["far", "question-circle"] }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm.$slots.description
                          ? _c(
                              "div",
                              { staticClass: "sub header" },
                              [_vm._t("description")],
                              2
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm.$slots.actions
                      ? _c(
                          "div",
                          {
                            class:
                              "tui tuim ui floated right aligned right " +
                              _vm.rightSize +
                              " wide column"
                          },
                          [_vm._t("actions")],
                          2
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        ])
      ]),
      _vm._t("bottom"),
      _vm.$slots.notifications
        ? _c(
            "div",
            { staticClass: "ui container flex centered notifications" },
            [_vm._t("notifications")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }