import services from '@/services'
import NProgress from 'nprogress'

const { http, trackpay } = services

const state = {
  token: localStorage.getItem('token') || '',
  user: JSON.parse(localStorage.getItem('user')) || {},
  status: '',
  isDirectMail: false,
  hasDebitAccount: true
}

const mutations = {
  'SET_AUTH_STATUS' (state, status = 'loading') {
    state.status = status
  },

  'SET_AUTH_TOKEN' (state, token) {
    localStorage.setItem('token', token)
    http.defaults.headers['Authorization'] = `Bearer ${token}`
    state.token = token
  },

  'SET_AUTH_USER' (state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },

  'RESET_AUTH_TOKEN' (state) {
    state.token = ''
    http.defaults.headers['Authorization'] = ''
    localStorage.removeItem('token')
  },

  'SET_DIRECT_MAIL' (state, payload) {
    state.isDirectMail = !!payload
    localStorage.removeItem('isDirectMail')
    localStorage.setItem('isDirectMail', state.isDirectMail)
  },

  'HAS_DEBIT_ACCOUNT' (state, payload) {
    state.hasDebitAccount = !!payload
    localStorage.removeItem('hasDebitAccount')
    localStorage.setItem('hasDebitAccount', state.hasDebitAccount)
  }
}

const actions = {
  authenticateUser ({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_AUTH_STATUS', 'loading')
      params = { ...{ grant_type: 'password' }, ...params }
      trackpay.auth.oauth(params)
        .then(resp => {
          const { organization } = resp
          commit('SET_AUTH_TOKEN', resp.access_token)
          commit('SET_AUTH_USER', resp.user)
          commit('SET_DIRECT_MAIL', organization.only_direct_call)
          commit('HAS_DEBIT_ACCOUNT', organization.exists_debit_account)
          return resp
        })
        .then(resp => resolve(resp))
        .catch(err => {
          dispatch('logout', 'error')
          NProgress.done()
          reject(err)
        })
    })
  },

  logout ({ commit }, status = '') {
    commit('SET_AUTH_STATUS', status)
    commit('RESET_AUTH_TOKEN')
    commit('SET_AUTH_USER', {})
  }
}

const hasStore = state =>
  Object.keys(state.user).length === 0 && state.user.constructor === Object

const getters = {
  isLoggedIn: state => !!state.token,
  getUser: state => hasStore ? state.user : JSON.parse(localStorage.getItem('user')),
  isDirectMail: state => (localStorage.getItem('isDirectMail') === 'true'),
  hasDebitAccount: state => (localStorage.getItem('hasDebitAccount') === 'true')
}

export default {
  state,
  actions,
  mutations,
  getters
}
