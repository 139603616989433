<template>
  <div
    id="page-content"
    :class="`tui tuim ui ${size} wide column page-content ${customClass}`"
  >
    <div class="tui tuim inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TPageContent',
  props: {
    size: {
      type: String,
      default: 'eleven'
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "collect/page-content";
</style>
