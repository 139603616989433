<template>
  <div>
    <div class="ui row">
      <h2>Dados da Transação</h2>
    </div>

    <div class="tui tuim ui card">
      <div class="wrapper-value-transactions" :class="statusClass">
        <div class="wrap-title-transactions">
          <span class="status-icon" v-html="statusIcon(statusClass)" />
          <div>
            <h2 class="title-transactions" v-text="titleCard" />
            <p class="description-transactions" v-text="createdDate" />
          </div>
        </div>
        <div class="wrap-value-transactions">
          <h3 class="price" v-text="formatedValue" />
          <span class="currency" v-text="currency" />
        </div>
      </div>

      <!-- nao vai entrar agora -->
      <div v-if="false" class="wrap-transactions-summary">
        <ul class="list-transactions-summary">
          <li class="list-transactions-summary--item">
            <div class="wrap-description-item-list">
              <t-icon :icon="[ 'fas', 'percentage' ]" class="tui tuim icon" />
              <h5 class="text-description">Taxa de processamento</h5>
            </div>
            <div class="wrap-description-values-item-list">
              <span class="value-percent">3</span>
              <t-icon :icon="[ 'fas', 'equals' ]" class="tui tuim icon" />
              <span class="value-currency">0,90</span>
            </div>
          </li>

          <li class="list-transactions-summary--item">
            <div class="wrap-description-item-list">
              <t-icon :icon="[ 'fas', 'user' ]" class="tui tuim icon" />
              <h5 class="text-description">Nome da ONG</h5>
            </div>
            <div class="wrap-description-values-item-list">
              <span class="value-percent">50</span>
              <t-icon :icon="[ 'fas', 'equals' ]" class="tui tuim icon" />
              <span class="value-currency">15,00</span>
            </div>
          </li>

          <li class="list-transactions-summary--item">
            <div class="wrap-description-item-list">
              <t-icon :icon="[ 'fas', 'user-friends' ]" class="tui tuim icon" />
              <h5 class="text-description">Nome do primeiro recebedor</h5>
            </div>
            <div class="wrap-description-values-item-list">
              <span class="value-percent">25</span>
              <t-icon :icon="[ 'fas', 'equals' ]" class="tui tuim icon" />
              <span class="value-currency">7,50</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

  import { dateTimeFormatDate, currencyFormat, getStatusIcon } from '@/helpers'

  export default {
    name: 'TransactionsSummmaryCard',

    props: {
      statusClass: {
        type: String,
        default: 'pending'
      },
      titleCard: {
        type: String,
        default: ''
      },
      descriptionCard: {
        type: String,
        default: ''
      },
      value: {
        type: Number,
        default: 0
      },
      currency: {
        type: String,
        default: 'BRL'
      }
    },

    data () {
      return {
        iconStatus: ''
      }
    },

    computed: {
      createdDate: ({ descriptionCard }) => descriptionCard ? `${dateTimeFormatDate(descriptionCard)}h` : "",

      formatedValue: ({ value }) => currencyFormat(`${value}`),

    },

    methods: {

      statusIcon: (status) => getStatusIcon(status),

    }

  }
</script>

<style lang="scss" >
@import '@/assets/sass/status-colors';

$gray-lighter: #7a7a7a;

.wrapper-value-transactions {
  background: #eee;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;

  &.error, &.hard_bounce, &.canceled { background: $negative-1; }
  &.success, &.paid, &.over_paid, &.under_paid { background: $green-300; }
  &.soft_bounce { background: $yellow-300; }
  &.pending, &.generated { background: $gray-300; }
  &.charged_back, &.refunded { background: $purple-300; }
  &.expired { background: $gray-500; }


  .wrap-title-transactions {
    display: flex;
    align-items: baseline;

    .status-icon .icon {
      color: $white;
      font-size: 14px;
    }

    .title-transactions {
      color: $white;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: capitalize;
    }

    .description-transactions {
      color: $white;
    }
  }

  .wrap-value-transactions {
    text-align: right;

    .price {
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      color: $white;
      margin-bottom: 0;
    }

    .currency {
      font-weight: 300;
      color: $white;
    }
  }
}

.list-transactions-summary {
  list-style: none;
  padding: 0;
  margin: 0;

  &--item {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #e2e2e3;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }
  }

  .wrap-description-item-list {
    display: flex;
    align-items: center;

    .icon {
      color: $gray-300;
      width: 16px;
    }

    .text-description {
      font-size: 16px;
      line-height: 20px;
      color: $gray-lighter;
      margin: 0 0 0 10px;
    }
  }

  .wrap-description-values-item-list {
    text-align: right;

    .value-percent {
      font-size: 16px;
      line-height: 20px;
      color: #a3a3a3;

      &:after {
        content: ' %'
      }
    }

   .icon {
      color: $gray-300;
      width: 16px;
      margin: 0 0 0 35px
    }

    .value-currency {
      font-size: 16px;
      line-height: 20px;
      color: $gray-lighter;
      width: 75px;
      display: inline-block;
    }
  }
}
</style>
