<template>
  <div class="no-register">
    <t-icon :icon="[ 'fal', 'exclamation-circle' ]" class="clock-icon"/>
    <h1 class="header" v-text="header" />
    <h2 class="sub header" v-text="subheader" />
  </div>
</template>

<script>
export default {
  name: 'TNoData',

  props: {

    header: {
      type: String,
      default: "Não econtramos nenhum registro"
    },

    subheader: {
      type: String,
      default: "Não há registros"
    }

  }
}
</script>

<style lang="scss">
$black-100: #333;

.no-register {
  text-align: center;
  pointer-events: none;

  .clock-icon {
    color: #3cb3f5;
    width: 140px;
    height: 100px;
  }

  .header {
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    color: $black-0;
    margin-bottom: 0px;
  }

  .sub.header {
    font-size: 16px;
    line-height: 20px;
    color: $black-0;
    font-weight: 200;
    margin-top: 0px;
  }
}
</style>
