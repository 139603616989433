var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tui tuim ui card" }, [
      _c("div", { staticClass: "tui tuim ui header" }, [
        _c("h2", [_vm._v("Detalhes da transação")])
      ]),
      _c("form", { staticClass: "tui tuim ui form" }, [
        _c("div", { staticClass: "content spacer" }, [
          _c("div", { staticClass: "fields" }, [
            _c("div", { staticClass: "ten wide field" }, [
              _c("div", { staticClass: "field disabled" }, [
                _c("label", [_vm._v("Operadora")]),
                _c("input", {
                  attrs: { type: "text", name: "holder", value: "Trackmob" }
                })
              ]),
              _c("div", { staticClass: "field disabled" }, [
                _c("label", [_vm._v("TID")]),
                _c("input", {
                  attrs: { type: "text", name: "tid", value: "91541590" }
                })
              ]),
              _c("div", { staticClass: "field disabled" }, [
                _c("label", [_vm._v("NSU")]),
                _c("input", {
                  attrs: { type: "text", name: "nsu", value: "91541590" }
                })
              ]),
              _c("div", { staticClass: "field disabled" }, [
                _c("label", [_vm._v("Bandeira")]),
                _c("input", {
                  attrs: {
                    type: "text",
                    name: "card[type]",
                    value: "Master card"
                  }
                })
              ]),
              _c("div", { staticClass: "field disabled" }, [
                _c("label", [_vm._v("Código de autorização")]),
                _c("input", {
                  attrs: { type: "text", name: "code", value: "450641" }
                })
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }