import { getYear } from './helpers'

const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

const shortMonthNames = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
]

const repeatLevels = {
  'color-1': [0, 1],
  'color-2': [1, 2],
  'color-3': [2, 3],
  'color-4': [3, 4],
  'color-5': [4, 5],
  'color-6': [5, 6],
  'color-7': [6, 7],
  'color-8': [7, 8],
  'color-9': [8, 9],
  'color-10': [9, 10],
  'color-11': [10, 11],
  'color-12': [11, 12],
  'color-13': [12, 13],
  'color-14': [13, 14],
  'color-15': [14, 15],
  'color-16': [15, 16],
  'color-17': [16, 17],
  'color-18': [17, 18],
  'color-19': [18, 19],
  'color-20': [19, 20],
  'color-21': [20, 21],
  'color-22': [21, 22],
  'color-23': [22, 23],
  'color-24': [23, 24],
  'color-25': [24, 25],
  'color-26': [25, 26],
  'color-27': [26, 27],
  'color-28': [27, 28],
  'color-29': [28, 29],
  'color-30': [29, 30],
  'color-31': [30, 31],
  'color-32': [31, 32],
  'color-33': [32, 33],
  'color-34': [33, 34],
  'color-35': [34, 35],
  'color-36': [35, 36],
  'color-37': [36, 37],
  'color-38': [37, 38],
  'color-39': [38, 39],
  'color-40': [39, 40],
  'color-41': [40, 41],
  'color-42': [41, 42],
  'color-43': [42, 43],
  'color-44': [43, 44],
  'color-45': [44, 45],
  'color-46': [45, 46],
  'color-47': [46, 47],
  'color-48': [47, 48],
  'color-49': [48, 49],
  'color-50': [49, 50],
  'color-51': [50, 51],
  'color-52': [51, 52],
  'color-53': [52, 53],
  'color-54': [53, 54],
  'color-55': [54, 55],
  'color-56': [55, 56],
  'color-57': [56, 57],
  'color-58': [57, 58],
  'color-59': [58, 59],
  'color-60': [59, 60],
  'color-61': [60, 61],
  'color-62': [61, 62],
  'color-63': [62, 63],
  'color-64': [63, 64],
  'color-65': [64, 65],
  'color-66': [65, 66],
  'color-67': [66, 67],
  'color-68': [67, 68],
  'color-69': [68, 69],
  'color-70': [69, 70],
  'color-71': [70, 71],
  'color-72': [71, 72],
  'color-73': [72, 73],
  'color-74': [73, 74],
  'color-75': [74, 75],
  'color-76': [75, 76],
  'color-77': [76, 77],
  'color-78': [77, 78],
  'color-79': [78, 79],
  'color-80': [79, 80],
  'color-81': [80, 81],
  'color-82': [81, 82],
  'color-83': [82, 83],
  'color-84': [83, 84],
  'color-85': [84, 85],
  'color-86': [85, 86],
  'color-87': [86, 87],
  'color-88': [87, 88],
  'color-89': [88, 89],
  'color-90': [89, 90],
  'color-91': [90, 91],
  'color-92': [91, 92],
  'color-93': [92, 93],
  'color-94': [93, 94],
  'color-95': [94, 95],
  'color-96': [95, 96],
  'color-97': [96, 97],
  'color-98': [97, 98],
  'color-99': [98, 99],
  'color-100': [99, 100]
}

const labels = {
  time: 'Período',
  people: 'Quantidade',
  weekOf: 'Semana'
}

export default {
  monthNames,
  shortMonthNames,
  repeatLevels,
  labels,
  timeInterval: 'monthly',
  drawEmptyCells: true,
  rawNumberOnHover: true,
  displayAbsoluteValues: false,
  initialIntervalNumber: 1,
  classPrefix: 'cohort-',

  formatHeaderLabel: function (i) {
    return (this.initialIntervalNumber - 1 + i).toString()
  },

  formatDailyLabel: function (date, i) {
    date.setDate(date.getDate() + i)
    return this.monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + getYear(date)
  },

  formatWeeklyLabel: function (date, i) {
    date.setDate(date.getDate() + i * 7)
    return this.labels.weekOf + ' ' + this.shortMonthNames[date.getMonth()] + ' ' +
      date.getDate() + ', ' + getYear(date)
  },

  formatMonthlyLabel: function (date, i) {
    date.setMonth(date.getMonth() + i)
    return this.monthNames[date.getMonth()] + ' ' + getYear(date)
  },

  formatYearlyLabel: function (date, i) {
    return date.getYear() + 1900 + i
  }
}
