var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "tui tuim ui card" }, [
      _c(
        "div",
        { staticClass: "wrapper-value-transactions", class: _vm.statusClass },
        [
          _c("div", { staticClass: "wrap-title-transactions" }, [
            _c("span", {
              staticClass: "status-icon",
              domProps: { innerHTML: _vm._s(_vm.statusIcon(_vm.statusClass)) }
            }),
            _c("div", [
              _c("h2", {
                staticClass: "title-transactions",
                domProps: { textContent: _vm._s(_vm.titleCard) }
              }),
              _c("p", {
                staticClass: "description-transactions",
                domProps: { textContent: _vm._s(_vm.createdDate) }
              })
            ])
          ]),
          _c("div", { staticClass: "wrap-value-transactions" }, [
            _c("h3", {
              staticClass: "price",
              domProps: { textContent: _vm._s(_vm.formatedValue) }
            }),
            _c("span", {
              staticClass: "currency",
              domProps: { textContent: _vm._s(_vm.currency) }
            })
          ])
        ]
      ),
      false
        ? _c("div", { staticClass: "wrap-transactions-summary" }, [
            _c("ul", { staticClass: "list-transactions-summary" }, [
              _c("li", { staticClass: "list-transactions-summary--item" }, [
                _c(
                  "div",
                  { staticClass: "wrap-description-item-list" },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "percentage"] }
                    }),
                    _c("h5", { staticClass: "text-description" }, [
                      _vm._v("Taxa de processamento")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrap-description-values-item-list" },
                  [
                    _c("span", { staticClass: "value-percent" }, [_vm._v("3")]),
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "equals"] }
                    }),
                    _c("span", { staticClass: "value-currency" }, [
                      _vm._v("0,90")
                    ])
                  ],
                  1
                )
              ]),
              _c("li", { staticClass: "list-transactions-summary--item" }, [
                _c(
                  "div",
                  { staticClass: "wrap-description-item-list" },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "user"] }
                    }),
                    _c("h5", { staticClass: "text-description" }, [
                      _vm._v("Nome da ONG")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrap-description-values-item-list" },
                  [
                    _c("span", { staticClass: "value-percent" }, [
                      _vm._v("50")
                    ]),
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "equals"] }
                    }),
                    _c("span", { staticClass: "value-currency" }, [
                      _vm._v("15,00")
                    ])
                  ],
                  1
                )
              ]),
              _c("li", { staticClass: "list-transactions-summary--item" }, [
                _c(
                  "div",
                  { staticClass: "wrap-description-item-list" },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "user-friends"] }
                    }),
                    _c("h5", { staticClass: "text-description" }, [
                      _vm._v("Nome do primeiro recebedor")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "wrap-description-values-item-list" },
                  [
                    _c("span", { staticClass: "value-percent" }, [
                      _vm._v("25")
                    ]),
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "equals"] }
                    }),
                    _c("span", { staticClass: "value-currency" }, [
                      _vm._v("7,50")
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ui row" }, [
      _c("h2", [_vm._v("Dados da Transação")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }