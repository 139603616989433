import { getMonth } from '@/helpers'
import gql from 'graphql-tag'
import { getQueryFilesFinished, getQueryFilesNewShipment, getQueryFilesWaitingReturn } from '@/queries/files/file.queries'

const nowDate = new Date(Date.now())
const currentYear = nowDate.getFullYear()
const currentMonty = getMonth()

const state = {
  fileData: {},
  fileHasErrors: false,
  showBarStatus: false,
  widgetLoading: false,
  listLoading: false,
  hasButtonBarStatus: false,
  processCompleted: false,
  progress: 0,
  widgetList: [],
  fileList: [],
  fileListFinished: [],
  fileListNewShipment: [],
  fileListWaitingReturn: [],
  metaDataListWaitingReturn: {},
  metaDataListNewShipment: {},
  metaDataListFinished: {},
  currentYear,
  currentMonty,
  urlFileNewShipment: '',
  totalFilesNewShipment: 0,
  walletFilter: '',
  payments: [],
  metaDataPaymentsList: {},
  isGeneratingFile: false,
  loadingNewShipment: false,
  loadingWaitingReturn: false,
  loadingFinished: false
}

const mutations = {
  'TOGGLE_LOADING_WIDGET' (state, payload = false) {
    state.widgetLoading = !!payload
  },
  'TOGGLE_LOADING_BAR_STATUS' (state, payload = false) {
    state.showBarStatus = !!payload
  },
  'TOGGLE_LOADING_LIST' (state, payload = false) {
    state.listLoading = !!payload
  },
  'SET_FILES_WIDGETS' (state, payload) {
    state.widgetList = payload
  },
  'SET_FILES_LIST' (state, payload) {
    state.fileList = payload
  },
  'SET_FILES_LIST_FINISHED' (state, payload) {
    state.fileListFinished = payload
  },
  'SET_METADATA_LIST_FINISHED' (state, payload) {
    state.metaDataListFinished = payload
  },
  'SET_FILES_LIST_NEW_SHIPMENT' (state, payload) {
    state.fileListNewShipment = payload
  },
  'SET_METADATA_LIST_NEW_SHIPMENT' (state, payload) {
    state.metaDataListNewShipment = payload
  },
  'SET_FILES_LIST_WAITING_RETURN' (state, payload) {
    state.fileListWaitingReturn = payload
  },
  'SET_METADATA_LIST_WAITING_RETURN' (state, payload) {
    state.metaDataListWaitingReturn = payload
  },
  'SET_CURRENT_MONTH' (state, payload) {
    state.currentMonty = payload
  },
  'SET_CURRENT_YEAR' (state, payload) {
    state.currentYear = payload
  },
  'SET_BUTTON_BAR_STATUS' (state, payload) {
    state.hasButtonBarStatus = payload
  },
  'SET_FILE_DATA' (state, payload) {
    state.fileData = payload
  },
  'TOGGLE_FILE_HAS_ERRORS' (state, payload = false) {
    state.fileHasErrors = !!payload
  },
  'TOGGLE_PROCESS_COMPLETED' (state, payload = false) {
    state.processCompleted = !!payload
  },
  'SET_UPLOAD_FILE_PROGRESS' (state, payload) {
    state.progress = payload
  },
  'SET_URL_FILE_NEW_SHIPMENT' (state, payload) {
    state.urlFileNewShipment = payload
  },
  'SET_TOTAL_FILES_NEW_SHIPMENT' (state, payload) {
    state.totalFilesNewShipment = payload
  },
  'SET_WALLET_FILTER' (state, payload) {
    state.walletFilter = payload
  },
  'SET_PAYMENTS_LIST' (state, payload) {
    state.payments = payload
  },
  'SET_METADATA_PAYMENTS_LIST' (state, payload) {
    state.metaDataPaymentsList = payload
  },
  'TOGGLE_GENERATING_FILE' (state, payload = false) {
    state.isGeneratingFile = !!payload
  },
  'TOGGLE_LOADING_NEW_SHIPMENT' (state, payload = false) {
    state.loadingNewShipment = !!payload
  },
  'TOGGLE_LOADING_WAITING_RETURN' (state, payload = false) {
    state.loadingWaitingReturn = !!payload
  },
  'TOGGLE_LOADING_FINISHED' (state, payload = false) {
    state.loadingFinished = !!payload
  },
}

const actions = {

  // files new shipment
  getFilesNewShipment ({ commit }) {

    const walletID = !state.walletFilter || state.walletFilter === "0" ? "" : state.walletFilter

    commit('TOGGLE_LOADING_NEW_SHIPMENT', true)

    getQueryFilesNewShipment({ walletID }).then(res => {
      const { result } = res.data.availablePaymentIntentsByWallet
      this.filesNewShipment = result.map((file) => ({
        paymentMethod: `${file.paymentMethodLabel}: ${file.bankName}`,
        registers: '',
        delayed: file.expired,
        billing: file.toProcess,
        recovery: file.toReprocess,
        bankCode: file.bankCode,
        id: file.bankId,
        walletId: file.walletId
      }))
      this.erros = null
      commit('SET_FILES_LIST_NEW_SHIPMENT', this.filesNewShipment)
      commit('SET_TOTAL_FILES_NEW_SHIPMENT', this.filesNewShipment.length)
      commit('TOGGLE_LOADING_NEW_SHIPMENT', false)
    }).catch(e => {
      this.erros = e
      commit('TOGGLE_LOADING_NEW_SHIPMENT', false)
    })
  },

  // files waiting return
  getFilesWaitingReturn ({ commit }, { year, month, page, limit }) {

    const beginDate = new Date(year, month - 1, 1).toISOString().split("T")[0]
    const endDate =  new Date(year, month, 0).toISOString().split("T")[0]
    const walletID = !state.walletFilter || state.walletFilter === "0" ? "" : state.walletFilter

    commit('TOGGLE_LOADING_WAITING_RETURN', true)

    getQueryFilesWaitingReturn( { beginDate, endDate, walletID, page, limit } ).then( res => {
      const { collection } = res.data.pendingOutputProcessRequests.result
      const { metadata } = res.data.pendingOutputProcessRequests.result
      this.erros = null
      commit('SET_FILES_LIST_WAITING_RETURN', collection)
      commit('SET_METADATA_LIST_WAITING_RETURN', metadata)
      commit('TOGGLE_LOADING_WAITING_RETURN', false)
    }).catch(e => {
      this.erros = e
      commit('TOGGLE_LOADING_WAITING_RETURN', false)
    })
  },

  // files finished
  getFilesFinished ({ commit }, { year, month, page, limit }) {

    const beginDate = new Date(year, month - 1, 1).toISOString().split("T")[0]
    const endDate =  new Date(year, month, 0).toISOString().split("T")[0]
    const walletID = !state.walletFilter || state.walletFilter === "0" ? "" : state.walletFilter

    commit('TOGGLE_LOADING_FINISHED', true)

    getQueryFilesFinished( { beginDate, endDate, walletID, page, limit } ).then( res => {
      const { collection } = res.data.processedOutputProcessRequests.result
      const { metadata } = res.data.processedOutputProcessRequests.result
      this.erros = null
      commit('SET_FILES_LIST_FINISHED', collection)
      commit('SET_METADATA_LIST_FINISHED', metadata)
      commit('TOGGLE_LOADING_FINISHED', false)
    }).catch(e => {
      this.erros = e
      commit('TOGGLE_LOADING_FINISHED', false)
    })
  },

  getYearListFiles ({ commit }) {
    // enquanto não existe api que retorna os anos
    let yearList = []
    for (let i = 0; i <  3; i++) {

      commit('TOGGLE_LOADING_WIDGET', true)

      let objYear = { year: currentYear - i, monthlyMetrics: [] }
      Vue.prototype.$api.query({
        query: gql`{
          countPendingOutputProcessRequests(year:${objYear.year}) {
            result {
              year month pendingOutputProcessRequests
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then(res => {
        const { result } = res.data.countPendingOutputProcessRequests
        for (let pending of result) {
          objYear.monthlyMetrics.push({
            month: pending.month,
            new: 0,
            withoutReturn: pending.pendingOutputProcessRequests
          })
        }
        yearList.push(objYear)
        commit('TOGGLE_LOADING_WIDGET', false)
        commit('SET_FILES_WIDGETS', yearList)
      }).catch(e => {
        commit('TOGGLE_LOADING_WIDGET', false)
      })
    }
  },

  toggleLoadingBarStatus ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_BAR_STATUS', isLoading)
  },

  toggleFileHasErrors ({ commit }, payload = false) {
    commit('TOGGLE_FILE_HAS_ERRORS', payload)
  },

  toggleProcessCompleted ({ commit }, payload = false) {
    commit('TOGGLE_PROCESS_COMPLETED', payload)
  },

  setButtonStatusBar ({ commit }, payload) {
    commit('SET_BUTTON_BAR_STATUS', payload)
  },

  setUploadFileProgress ({ commit }, payload ) {
    commit('SET_UPLOAD_FILE_PROGRESS', payload)
  },

  setUrlFileNewShipment ({ commit }, payload ) {
    commit('SET_URL_FILE_NEW_SHIPMENT', payload)
  },

  toggleLoadingWidget ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_WIDGET', isLoading)
  },

  setTotalFilesNewShipment ({ commit }, payload ) {
    commit('SET_TOTAL_FILES_NEW_SHIPMENT', payload)
  },

  setWalletFilter ({ commit }, payload ) {
    commit('SET_WALLET_FILTER', payload)
  },

  setCurrentYear ({ commit }, payload ) {
    commit('SET_CURRENT_YEAR', payload)
  },

  setCurrentMonth ({ commit }, payload ) {
    commit('SET_CURRENT_MONTH', payload)
  },

  setPaymentsList ({ commit }, payload ) {
    commit('SET_PAYMENTS_LIST', payload)
  },

  setMetaDataPaymentsList ({ commit }, payload ) {
    commit('SET_METADATA_PAYMENTS_LIST', payload)
  },

  toggleGeneratingFile ({ commit }, payload ) {
    commit('TOGGLE_GENERATING_FILE', payload)
  },

  toggleLoadingNewShipment ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_NEW_SHIPMENT', isLoading)
  },

  toggleLoadingWaitingReturn ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_WAITING_RETURN', isLoading)
  },

  toggleLoadingFinished ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_FINISHED', isLoading)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
