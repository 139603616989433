var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui table" }, [
    _c("table", { staticClass: "ui basic table ordered-table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _vm._l(_vm.headers, function(head, i) {
              return _c(
                "th",
                {
                  key: i,
                  staticClass: "table-header",
                  class: head.classes,
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.orderTable(head.key, head.order)
                    }
                  }
                },
                [
                  _vm.sortable
                    ? _c("i", {
                        class:
                          "fas order-icon " +
                          (head.order === "desc"
                            ? "fa-caret-down"
                            : head.order === "asc"
                            ? "fa-caret-up"
                            : "")
                      })
                    : _vm._e(),
                  _vm._v("\n           \n          "),
                  _c("span", {
                    staticClass: "title",
                    domProps: { textContent: _vm._s(head.title) }
                  })
                ]
              )
            }),
            _vm.$scopedSlots.actions
              ? _c("th", {
                  staticClass: "table-header",
                  domProps: { innerHTML: _vm._s("&nbsp;") }
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "tbody",
        { staticClass: "basic-table-body" },
        [
          _vm.isLoading
            ? _c(
                "tr",
                _vm._l([].concat(Array(_vm.headerSize)), function(item, k) {
                  return _c(
                    "td",
                    { key: k },
                    [_c("t-placeholder", { attrs: { lines: 1 } })],
                    1
                  )
                }),
                0
              )
            : _vm._l(_vm.dataList, function(items, i) {
                return _c(
                  "tr",
                  { key: i, class: { clickable: _vm.routeParams.clickToView } },
                  [
                    _vm.routeParams.clickToView
                      ? _vm._l(
                          _vm.dataFormatter ? _vm.dataFormatter(items) : items,
                          function(item, k) {
                            return _c("router-link", {
                              key: k,
                              staticClass: "columns",
                              attrs: {
                                tag: "td",
                                to: {
                                  name: _vm.routeParams.routeName,
                                  params: _vm.routeParams.params
                                    ? _vm.resolveRouteParams(_vm.dataList[i])
                                    : { id: _vm.itemId(_vm.dataList[i]) }
                                }
                              },
                              domProps: { innerHTML: _vm._s(item) },
                              nativeOn: {
                                mouseover: function($event) {
                                  return _vm.setItemFromEmiter(_vm.dataList[i])
                                },
                                click: function($event) {
                                  return _vm.setItemFrom(_vm.dataList[i])
                                }
                              }
                            })
                          }
                        )
                      : _vm._l(
                          _vm.dataFormatter ? _vm.dataFormatter(items) : items,
                          function(item, k) {
                            return _c("td", {
                              key: k,
                              staticClass: "columns",
                              domProps: { innerHTML: _vm._s(item) }
                            })
                          }
                        ),
                    _vm.$scopedSlots.actions
                      ? _c(
                          "td",
                          [_vm._t("actions", null, null, _vm.dataList[i])],
                          2
                        )
                      : _vm._e()
                  ],
                  2
                )
              })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }