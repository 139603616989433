var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "tui tuim ui " + (_vm.fluid ? "fluid" : "") + " placeholder" },
    [
      _vm.header
        ? _c(
            "div",
            { class: (_vm.image ? "image" : "") + " header" },
            _vm._l(_vm.lines, function(line, i) {
              return _c("div", { key: i, staticClass: "line" })
            }),
            0
          )
        : _vm._e(),
      _vm.paragraph
        ? _c(
            "div",
            { staticClass: "paragraph" },
            _vm._l(_vm.lines, function(line, k) {
              return _c("div", { key: k, staticClass: "line" })
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }