var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "ui active " + (_vm.inverted ? "inverted" : "") + " dimmer" },
    [_c("div", { staticClass: "ui loader" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }