import routes from '../routes'

const {
  NODE_ENV,
  VUE_APP_COLLECT_API_URL,
  VUE_APP_API_URL, // eslint-disable-line
  VUE_APP_ENABLE_COMING_SOON,
  VUE_APP_COLLECT_GA_ID
} = process.env

export const isProduction = NODE_ENV === 'production'
export const isStaging = NODE_ENV === 'staging'
export const enableComingSoon = VUE_APP_ENABLE_COMING_SOON === 'true'
export const gaID = VUE_APP_COLLECT_GA_ID
export const API_PREFIX = 'v1'
export const tenant = window.location.host.split('.')[0]

export const router = {
  hashbang: true,
  linkActiveClass: 'active',
  history: true,
  mode: 'history',
  routes
}

let collectEndpointUrl = `${VUE_APP_COLLECT_API_URL}/${API_PREFIX}`
let collectBaseUrl = VUE_APP_COLLECT_API_URL

if (isProduction) {
  collectBaseUrl = `https://${tenant}.trackpay.trackmob.com.br`
  collectEndpointUrl = `${collectBaseUrl}/${API_PREFIX}`
} else if (isStaging) {
  collectBaseUrl = `https://${tenant}.trackpay.trackmobdev.com.br`
  collectEndpointUrl = `${collectBaseUrl}/${API_PREFIX}`
}

export default {
  isProduction,
  // collectApiUrl: !isProduction ? VUE_APP_API_URL : VUE_APP_COLLECT_API_URL,
  collectApiUrl: VUE_APP_COLLECT_API_URL,
  collectEndpointUrl,
  collectBaseUrl,
  router,
  pageTitle: { suffix: ' | Trackmob Collect - Beta' },
  logger: {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: false,
    showMethodName: false,
    separator: '',
    showConsoleColors: true
  }
}
