const state = {
  isLoading: false,
  transferenceTransactions: [],
  metaDataList: [],
  filterForm: {},
  filterItems: [],
  orderParams: {},
  headerData: {},
  transferenceData: {}
}

const mutations = {
  'SET_TRANSFERENCE_TRANSACTIONS' (state, payload) {
    state.transferenceTransactions = payload
  },
  'SET_METADATA_LIST' (state, payload) {
    state.metaDataList = payload
  },
  'TOGGLE_LOADING_LIST' (state, payload = false) {
    state.isLoading = !!payload
  },
  'ADD_ITEM' (state, item) {
    state.filterItems.push(item)
  },
  'REMOVE_ITEM' (state, item) {
    const { filterItems } = state
    filterItems.splice(filterItems.indexOf(item), 1)
  },
  'CLEAR_ITEMS' (state) {
    state.filterItems = []
  },
  'CLEAR_FILTER_FORM' (state) {
    state.filterForm = {}
  },
  'SET_ORDER_PARAMS' (state, payload) {
    state.orderParams = payload
  },
  'SET_HEADER_DATA' (state, payload) {
    state.headerData = payload
  },
  'SET_TRANSFERENCE_DATA' (state, payload) {
    state.transferenceData = payload
  },
}


const actions = {
  setTransferenceTransactions ({ commit }, payload ) {
    commit('SET_TRANSFERENCE_TRANSACTIONS', payload)
  },

  toggleLoadingList ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_LIST', isLoading)
  },

  addItem ({ commit }, item) {
    if (item.filterBy && item.equalTo && item.fieldOne) {
      commit('ADD_ITEM', item)
    }
  },

  removeItem ({ commit }, item) {
    commit('REMOVE_ITEM', item)
  },

  clearFilterItems ( { commit } ) {
    commit('CLEAR_ITEMS')
  },

  clearFilterForm ( { commit } ) {
    commit('CLEAR_FILTER_FORM')
  },

  setOrderParams ({ commit }, payload ) {
    commit('SET_ORDER_PARAMS', payload)
  },

  setHeaderData ({ commit }, payload ) {
    commit('SET_HEADER_DATA', payload)
  },

  setMetaDataList ({ commit }, payload ) {
    commit('SET_METADATA_LIST', payload)
  },

  setTransferenceData ({ commit }, payload ) {
    commit('SET_TRANSFERENCE_DATA', payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
