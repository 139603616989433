import gql from 'graphql-tag'

const query = gql `
  query wallets(
    $paymentMethod: ID = "debit_account"
  ) {
    wallets(
      paymentMethod: $paymentMethod,
    ) {
      collection {
        id
        bankName
        sequentialNumber
      }
    }
  }`

async function getWalletsList() {
  const { $api } = Vue.prototype
  const result = await $api.query({
          query,
      }).then(res => res)
      .catch(e => e)
  return result;
}

export { getWalletsList }
