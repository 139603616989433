var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "tui tuim ui " +
        (_vm.pointing ? "pointing" : "") +
        " " +
        _vm.extraClass +
        " dropdown"
    },
    [
      _vm.text
        ? _c("div", {
            staticClass: "text",
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        : _vm._e(),
      _c("t-icon", { staticClass: "tui tuim icon", attrs: { icon: _vm.icon } }),
      _c("div", { staticClass: "menu" }, [_vm._t("items")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }