import services from '@/services'
import {
  thousandsFormat,
  currencyFormat,
  statusWidh,
  statusBarMapItems,
  percentFormat
} from '@/helpers'

const { trackpay } = services

const leftGeneralData = data => [
  statusBarMapItems('valores processados', currencyFormat(data.successful_payments_amount), 'positive-1', 'BRL'),
  statusBarMapItems('falhas leves', currencyFormat(data.soft_bounces_amount), 'neutral-3', 'BRL'),
  statusBarMapItems('falhas graves', currencyFormat(data.hard_bounces_amount), 'negative-1', 'BRL'),
  statusBarMapItems('estornos', currencyFormat(data.refunds_amount), 'negative-3', 'BRL')
]

const rightGeneralData = data => [
  statusBarMapItems('contestações', thousandsFormat(data.chargebacks_count), 'negative-4'),
  statusBarMapItems('cobranças realizadas', thousandsFormat(data.charges_count), 'neutral-1'),
  statusBarMapItems('taxa de Efetividade', percentFormat(data.effectiveness_rate), 'neutral-2')
]

const statusGeneralData = (data, total) => [
  { width: statusWidh(data.successful_payments_amount, total), backgroundColor: '#78e5a4' },
  { width: statusWidh(data.soft_bounces_amount, total), backgroundColor: '#f9d96a' },
  { width: statusWidh(data.hard_bounces_amount, total), backgroundColor: '#fd9093' },
  { width: statusWidh(data.refunds_amount, total), backgroundColor: '#b489c4' }
  // { width: statusWidh(, total), backgroundColor: '#9da6ff' }
]

const rightTotalDonations = data => [
  statusBarMapItems('Doações únicas', thousandsFormat(data.unique_donations_count), 'positive-2'),
  statusBarMapItems('Recorrentes', thousandsFormat(data.recurring_donations_count), 'positive-3'),
  statusBarMapItems('Canceladas', thousandsFormat(data.canceled_donations_count), 'negative-1'),
  statusBarMapItems('Pausadas', thousandsFormat(data.paused_donations_count), 'negative-3')
]

const chartBar = (label, backgroundColor, data) => ({
  type: 'bar',
  borderColor: false,
  borderWidth: 0,
  fill: false,
  label,
  backgroundColor,
  data
})

const cohortFillNull = data => {
  if (data[0].length <= 13) {
    const _res = (13 - data[0].length)
    data[0] = [...data[0], ...Array(_res).fill(null)]
  }

  return data
}

const initialDate = (data, commit) => {
  const dataKeys = Object.keys(data)
  const first = dataKeys.length > 0 ? dataKeys[0] : ''

  if (first) {
    const month = first.split('/')[0]
    const year = first.split('/')[1]

    if (year && month) {
      commit('CHANGE_COHORT_INITIAL_DATE', new Date(year, (month - 1)))
    }
  }

  return data
}

const totalDonationsChartData = data => {
  const donationsPerMonth = data.donations_per_month
  const arrayValues = Object.values(donationsPerMonth)

  return {
    labels: Object.keys(donationsPerMonth),
    datasets: [
      chartBar('Doações únicas', '#3cb3f5', arrayValues.map(item => item.unique_count)),
      chartBar('Recorrentes', '#b4db75', arrayValues.map(item => item.recurring_count)),
      chartBar('Canceladas', '#fd9093', arrayValues.map(item => item.canceled_count)),
      chartBar('Pausadas', '#b489c4', arrayValues.map(item => item.paused_count))
    ]
  }
}

export default {
  toggleLoading ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_GENERAL_DATA', isLoading)
  },

  updateGeneralData ({ commit, dispatch }, params = {}) {
    return new Promise((resolve, reject) => {
      commit('RESET_GENERAL_DATA')
      commit('TOGGLE_LOADING_GENERAL_DATA', true)

      trackpay.transactions
        .getGeneralInfo(params)
          .then(data => {
            commit('CHANGE_GENERAL_LEFT_DATA', leftGeneralData(data))
            return data
          })
          .then(data => {
            commit('CHANGE_GENERAL_RIGHT_DATA', rightGeneralData(data))
            return data
          })
          .then(data =>
            commit('CHANGE_GENERAL_STATUS_BAR', statusGeneralData(data, data.total_amount)))
          .then(() => commit('TOGGLE_LOADING_GENERAL_DATA', false))
          .then(() => resolve())
          .catch(err => {
            commit('TOGGLE_LOADING_GENERAL_DATA', false)
            reject(err)
          })
    })
  },

  updateTotalDonations ({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_LOADING_TOTAL_DONATIONS', true)

      trackpay.donations
        .getTotalDonations(params)
          .then(data => {
            commit('CHANGE_TOTAL_DONATIONS_STATUS_BAR', rightTotalDonations(data))
            return data
          })
          .then(data => {
            commit('CHANGE_TOTAL_DONATIONS', `${thousandsFormat(data.total_donations_count)}`)
            return data
          })
          .then(data => {
            commit('CHANGE_TOTAL_DONATIONS_CHART_DATA', totalDonationsChartData(data))
            return data
          })
          .then(() => commit('TOGGLE_LOADING_TOTAL_DONATIONS', false))
          .then(() => resolve())
          .catch(err => {
            commit('TOGGLE_LOADING_TOTAL_DONATIONS', false)
            reject(err)
          })
    })
  },

  updateCohortData ({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      commit('TOGGLE_LOADING_COHORT_DATA', true)

      trackpay.donations
        .getRecurrenceFrictionRate(params)
          .then(data => initialDate(data, commit))
          .then(data => Object.values(data))
          .then(data => cohortFillNull(data))
          .then(cohortData => commit('CHANGE_COHORT_DATA', cohortData))
          .then(() => commit('TOGGLE_LOADING_COHORT_DATA', false))
          .then(() => resolve())
          .catch(err => {
            commit('TOGGLE_LOADING_COHORT_DATA', false)
            reject(err)
          })
    })
  }
}
