import gql from 'graphql-tag'

const query = gql `
  query availablePaymentIntentsByWallet(
    $walletID: ID
  ) {
    availablePaymentIntentsByWallet(
      walletId: $walletID,
    ) {
      result {
        paymentMethod paymentMethodLabel bankId bankName bankCode
        toProcess expired toReprocess walletId
      }
    }
  }`

/**
 *
 * @param {*} walletID
 */
async function getQueryFilesNewShipment(params = {}) {
    const { $api } = Vue.prototype
    const result = await $api.query({
            query,
            variables: params,
            fetchPolicy: 'no-cache'
        }).then(res => res)
        .catch(e => e)
    return result;
}

export { getQueryFilesNewShipment }
