<template>
  <div
    id="page-sidebar"
    :class="`tui tuim ui ${size} wide column page-sidebar ${customClass}`">
    <div class="tui tuim inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TPageSidebar',
  props: {
    size: {
      type: String,
      default: 'five'
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "collect/sidebar";
@import "collect/page-sidebar";
</style>
