var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui tuim ui card" }, [
    _vm._m(0),
    _c("form", { staticClass: "tui tuim ui form card-form" }, [
      _c("div", { staticClass: "content spacer" }, [
        _c("div", { staticClass: "fields" }, [
          _c("div", { staticClass: "sixteen wide field" }, [
            _c("div", { staticClass: "field" }, [
              _c("label", { staticClass: "label" }, [
                _vm._v("Forma de pagamento")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.transactionType,
                      expression: "transactionType"
                    }
                  ],
                  staticClass: "ui fluid dropdown disabled",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.transactionType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "credit_card" } }, [
                    _vm._v("Cartão de Crédito")
                  ]),
                  _c("option", { attrs: { value: "bank_slip" } }, [
                    _vm._v("Boleto")
                  ]),
                  _c("option", { attrs: { value: "debit_account" } }, [
                    _vm._v("Débito")
                  ]),
                  _c("option", { attrs: { value: "pix" } }, [_vm._v("Pix")])
                ]
              )
            ]),
            _vm.isLoading
              ? _c("div", [_c("t-placeholder", { attrs: { lines: 6 } })], 1)
              : _c(
                  "div",
                  [
                    _vm.transactionType === "credit_card"
                      ? _c("t-form-credit-card")
                      : _vm._e(),
                    _vm.transactionType === "bank_slip"
                      ? _c("t-form-bank-slip")
                      : _vm._e(),
                    _vm.transactionType === "debit_account"
                      ? _c("t-form-debit-account")
                      : _vm._e(),
                    _vm.transactionType === "pix" ? _c("t-form-pix") : _vm._e()
                  ],
                  1
                )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tui tuim ui header" }, [
      _c("h2", [_vm._v("Dados de cobrança")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }