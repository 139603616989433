import { getYear, getMonth } from '@/helpers'

export default {
  loadingGeneralData: false,
  generalData: {
    statusBar: [],
    leftItems: [],
    rightItems: []
  },
  loadingTotalDonations: false,
  totalDonations: {
    total: 0,
    statusBar: [],
    chartData: {}
  },
  loadingCohortData: false,
  cohortInitialDate: new Date(getYear(), getMonth()),
  cohortData: []
}
