const state = {
  isLoading: false,
  competences: [],
  orderParams: {},
  metaDataList: {}
}

const mutations = {
  'SET_COMPETENCES' (state, payload) {
    state.competences = payload
  },
  'TOGGLE_LOADING_LIST' (state, payload = false) {
    state.isLoading = !!payload
  },
  'SET_ORDER_PARAMS' (state, payload) {
    state.orderParams = payload
  },
  'SET_METADATA_COMPETENCES_LIST' (state, payload) {
    state.metaDataList = payload
  },
}

const actions = {
  setCompetences ({ commit }, payload ) {
    commit('SET_COMPETENCES', payload)
  },
  toggleLoadingList ({ commit }, isLoading = false) {
    commit('TOGGLE_LOADING_LIST', isLoading)
  },
  setOrderParams ({ commit }, payload ) {
    commit('SET_ORDER_PARAMS', payload)
  },
  setMetaDataList ({ commit }, payload ) {
    commit('SET_METADATA_COMPETENCES_LIST', payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
