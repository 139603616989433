<template>
  <div class="tui tuim ui card">
    <div class="tui tuim ui header">
      <h2>Dados de cobrança</h2>
    </div>
    <form class="tui tuim ui form card-form">

      <div class="content spacer">
        <div class="fields">
          <div class="sixteen wide field">
            <div class="field">
              <label class="label">Forma de pagamento</label>
              <select v-model="transactionType" class="ui fluid dropdown disabled">
                <option value="credit_card">Cartão de Crédito</option>
                <option value="bank_slip">Boleto</option>
                <option value="debit_account">Débito</option>
                <option value="pix">Pix</option>
              </select>
            </div>
            <div v-if="isLoading">
              <t-placeholder :lines="6" />
            </div>
            <div v-else>
              <t-form-credit-card v-if="transactionType === 'credit_card'" />
              <t-form-bank-slip v-if="transactionType === 'bank_slip'"/>
              <t-form-debit-account v-if="transactionType === 'debit_account'" />
              <t-form-pix v-if="transactionType === 'pix'" />
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TCardFormBilling',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    TFormBankSlip: () => import('./form-bank-slip.vue'),
    TFormCreditCard: () => import('./form-credit-card.vue'),
    TFormDebitAccount: () => import('./form-debit-account.vue'),
    TFormPix: () => import('./form-pix.vue')
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState({
      transactionData: ({ transactions }) => transactions.transactionData,
      transactionType: ({ transactions }) => transactions.transactionType,
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/colors';
@import '@/assets/sass/card-form';
</style>

