const state = {
  show: true
}

const mutations = {
  'TOGGLE_SHOW_COMING_SOON' (state, payload = false) {
    state.show = !!payload
  }
}

const actions = {
  showComingSoon ({ commit }, show = false) {
    commit('TOGGLE_SHOW_COMING_SOON', show)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
