import { getYear, getMonth } from '../helpers'

const filesParams = {
  year: getYear(),
  month: getMonth(),
  type: 'debit'
}

const comingSoon = process.env.VUE_APP_ENABLE_COMING_SOON === 'true'

const dashboard = {
  name: 'dashboard',
  path: '/dashboard',
  meta: {
    requiresAuth: true,
    comingSoon
  },
  component: () => import('@/pages/dashboard')
}

const files = {
  name: 'files',
  path: '/files/:year/:month/:type',
  linkExactActiveClass: 'is-active',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "files" */ '@/pages/files')
}

const filesEmpty = {
  name: 'files.empty',
  path: '/files',
  redirect: { name: 'files', params: filesParams },
  meta: {
    requiresAuth: true,
    comingSoon
  }
}

const filesYear = {
  name: 'files.year',
  path: '/files/:year/:type',
  redirect: { name: 'files', params: filesParams },
  meta: {
    requiresAuth: true,
    comingSoon
  }
}

const filesType = {
  name: 'files.type',
  path: '/files/:type',
  redirect: { name: 'files', params: filesParams },
  meta: {
    requiresAuth: true,
    comingSoon
  }
}

const filesSingle = {
  name: 'files.single',
  path: '/files/:year/:month/:type/:id',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "files" */ '@/pages/files/single.vue')
}

const filesBankSlip = {
  name: 'files.bankslip',
  path: '/files/bankslip/',
  meta: {
    requiresAuth: true,
    comingSoon
  },
  component: () => import(/* webpackChunkName: "files" */ '@/pages/files/bankslip.vue')
}

const donors = {
  name: 'donors',
  path: '/donors',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "donors" */ '@/pages/donors')
}

const donorsSingle = {
  name: 'donors.single',
  path: '/donors/:id',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "donors" */ '@/pages/donors/single.vue')
}

const donations = {
  name: 'donations',
  path: '/donations',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "donations" */ '@/pages/donations')
}

const donationsSingle = {
  name: 'donations.single',
  path: '/donations/:id',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "donations" */ '@/pages/donations/single.vue')
}

const transactions = {
  name: 'transactions',
  path: '/transactions',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "transactions" */ '@/pages/transactions')
}

const transactionsSingle = {
  name: 'transactions.single',
  path: '/transactions/:type/:id',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "transactions-single" */ '@/pages/transactions/single.vue')
}

const transactionsDownloadFile = {
  name: 'transactions.downloadFile',
  path: '/downloads/transactions/:reportId',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import(/* webpackChunkName: "transactions-downloadFile" */ '@/pages/transactions/downloadFile.vue')
}

const extract = {
  name: 'extract',
  path: '/extract',
  meta: {
    requiresAuth: true,
    comingSoon
  },
  component: () => import(/* webpackChunkName: "extract" */ '@/pages/extract')
}

const paymentconfig = {
  name: 'paymentconfig',
  path: '/paymentconfig',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import('@/pages/paymentconfig')
}

const competences = {
  name: 'competences',
  path: '/competences/:competenceId',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import('@/pages/competences')
}

const transferences = {
  name: 'transferences',
  path: '/transferences',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import('@/pages/transferences')
}

const transferenceTransaction = {
  name: 'transferenceTransaction',
  path: '/transferences/:id',
  meta: {
    requiresAuth: true,
    comingSoon: false
  },
  component: () => import('@/pages/transferences/single.vue')
}

export default {
  name: 'home',
  mode: 'history',
  linkExactActiveClass: 'is-active',
   path: '/',
  component: () => import('@/layouts/default.vue'),
  redirect: { name: 'dashboard' },
  meta: {
    requiresAuth: true
  },
  children: [
    dashboard,
    files,
    filesSingle,
    filesEmpty,
    filesYear,
    filesType,
    filesBankSlip,
    donors,
    donorsSingle,
    donations,
    donationsSingle,
    transactions,
    transactionsSingle,
    extract,
    paymentconfig,
    competences,
    transactionsDownloadFile,
    transferences,
    transferenceTransaction
  ]
}
