import auth from './auth.store'
import alert from './alert.store'
import files from './files.store'
import filter from './filter.store'
import breadcrumb from './breadcrumb.store'
import dashboard from './dashboard'
import comingSoon from './coming-soon'
import transactions from './transactions.store'
import donors from './donors.store'
import bankslip from './bankslip.store'
import donations from './donations.store'
import singleFile from './single.file.store'
import competences from './competences.store'
import notifications from './notifications.store'
import transferences from './transferences.store'
import transference from './transference.store'
import table from './table.store'
import navbar from './navbar.store'

export default {
  auth,
  alert,
  files,
  filter,
  breadcrumb,
  dashboard,
  comingSoon,
  transactions,
  donors,
  bankslip,
  donations,
  singleFile,
  competences,
  notifications,
  transferences,
  transference,
  table,
  navbar
}
