export default {
  name: 'TDropdown',
  props: {
    icon: {
      type: Array,
      default: () => [ 'fas', 'ellipsis-h' ]
    },
    pointing: {
      type: Boolean,
      default: false
    },
    extraClass: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
