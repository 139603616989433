export default {
  'TOGGLE_LOADING_GENERAL_DATA' (state, payload = false) {
    state.loadingGeneralData = !!payload
  },

  'TOGGLE_LOADING_TOTAL_DONATIONS' (state, payload = false) {
    state.loadingTotalDonations = !!payload
  },

  'TOGGLE_LOADING_COHORT_DATA' (state, payload = false) {
    state.loadingCohortData = !!payload
  },

  'RESET_GENERAL_DATA' (state) {
    state.generalData = {
      statusBar: [],
      leftItems: [],
      rightItems: []
    }
  },

  'CHANGE_GENERAL_LEFT_DATA' (state, leftItems) {
    state.generalData = { ...state.generalData, ...{ leftItems } }
  },

  'CHANGE_GENERAL_RIGHT_DATA' (state, rightItems) {
    state.generalData = { ...state.generalData, ...{ rightItems } }
  },

  'CHANGE_GENERAL_STATUS_BAR' (state, statusBar) {
    state.generalData = { ...state.generalData, ...{ statusBar } }
  },

  'CHANGE_TOTAL_DONATIONS' (state, total) {
    state.totalDonations = { ...state.totalDonations, ...{ total } }
  },

  'CHANGE_TOTAL_DONATIONS_STATUS_BAR' (state, statusBar) {
    state.totalDonations = { ...state.totalDonations, ...{ statusBar } }
  },

  'CHANGE_TOTAL_DONATIONS_CHART_DATA' (state, chartData) {
    state.totalDonations = { ...state.totalDonations, ...{ chartData } }
  },

  'CHANGE_COHORT_DATA' (state, data) {
    state.cohortData = data
  },

  'CHANGE_COHORT_INITIAL_DATE' (state, date) {
    state.cohortInitialDate = date
  }
}
