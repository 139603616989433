import { mapState, mapActions } from 'vuex'
import TProgressBar from '@/components/progress/bar.vue'

export default {
  name: 'TAlert',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'positive' // positive, info, warning or error
    },
    icon: {
      type: String,
      default: ''
    },
    close: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isVisible: ({ alert }) => alert.isVisible,
      alertTimer: ({ alert }) => alert.timer
    })
  },
  methods: {
    ...mapActions('alert', [ 'hideAlert' ]),
    remove (e) {
      e.preventDefault()
      this.hideAlert()
    }
  },
  components: {
    TProgressBar
  }
}
